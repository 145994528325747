import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddToOrderComponent } from 'src/app/modules/order-list/add-to-order/add-to-order.component';
import { OrderListApiService } from 'src/app/modules/order-list/order-list-api.service';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-add-shopping-product-dialog',
  templateUrl: './add-shopping-product-dialog.component.html',
  styleUrls: ['./add-shopping-product-dialog.component.less']
})
export class AddShoppingProductDialogComponent implements OnInit {
  order: Order = new Order();
  qtyList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  constructor(
    private api: OrderListApiService,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddToOrderComponent>,
  ) {}

  ngOnInit() {
    this.order.count =1;
  }

  submit() { 
    let postData= { 
      product_id: this.order.sku,
      count: this.order.count, 
      isServiceRepOrder:1, 
      // cart_id: this.data,
      project_id: this.data,

    }

    // const postData = {
    //   products: [{product_sku:this.order.sku,count:this.order.count}],
    //   isServiceRepOrder: 1,
    //   cart_id: this.apiService.projectId,
    // };
    // data.user_email = this.data;
    // data.parent_sku = this.order.sku;
    // data.count = this.order.count;
    // data.price = this.order.price;

    this.apiService.addDesignCartProduct(postData).subscribe((resp:any) => {
      if(resp.status){
      this.snackbar.open("Product Added Successfully", "", { duration: 2000 });
      this.dialogRef.close(true);
      }
    });
    let data= { 
      product_sku: this.order.sku,
      count: this.order.count, 
      isServiceRepOrder:1, 
      // cart_id: this.data,
      project_id: this.data,

    }
    

    this.apiService.addCartProduct(data).subscribe((resp:any) => {
      if(resp.status){  
      }
    }); 
  }
  close() {}
}
class Order {
  user_email: string;
  sku: string;
  count: number;
  price: string;
}