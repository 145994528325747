import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GalleryItem, Gallery, ImageItem } from "@ngx-gallery/core";
import {Location} from '@angular/common'
import { CookieService } from "ngx-cookie-service";
import { Lightbox } from "@ngx-gallery/lightbox";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { IActiveProduct, IProduct, IProductDetail, ISeo } from "src/app/shared/models";
import { IReviewsData, IUpgradeProduct } from "src/app/shared/models/product-detail-payload.interface";
import { ApiService, UtilsService, MatDialogUtilsService, SeoService, EventEmitterService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";
import { PixelService } from "src/app/shared/services/facebook-pixel/pixel.service";
import { ReviewDialogService } from "src/app/shared/services/matDialogUtils/review-dialog.service";
import { WishlistSnackbarService } from "src/app/shared/services/wishlist-service/wishlist-snackbar.service";



@Component({
  selector: 'app-product-restricted',
  templateUrl: './product-restricted.component.html',
  styleUrls: ['./product-restricted.component.less']
})
export class ProductRestrictedComponent implements OnInit, OnDestroy {
  @ViewChild("topContainer", { static: false }) topContainer: ElementRef;
  @ViewChild("gallery", { static: false }) galleryContainer: ElementRef;
  @ViewChild("itemTemplate", { static: true }) itemTemplate: TemplateRef<any>;

  product: IProduct;
  seoData: ISeo;
  productSubscription: Subscription;
  eventSubscription: Subscription;
  routeSubscription: Subscription;
  Math: Math = Math;
  selectedIndex: number;
  descriptionExist: boolean;
  isSwatchExist: boolean;
  isVariationExist: boolean;
  galleryId = "myLightBox";
  items: GalleryItem[] | any;
  isProductFetching = true;
  showDetails = false;
  spinner = "assets/image/spinner.gif";
  description: any;
  productPrice: any;
  productWasPrice: any;
  variations = [];
  topHeight = { "max-height": "0" };
  swatches = [];
  errorMessage = "";

  activeTab = "mr";
  priceData = {
    price: "",
    wasPrice: "",
  };
  selectedSwatch = {
    swatch_image: null,
    price: "",
    wasPrice: "",
  };
  hasValidWasPrice = true;
  quantity = 1;
  quantityArray = [];
  galleryRef = this.gallery.ref(this.galleryId);
  isSetItemInInventory = false;
  localStorageUser = {};
  activeProduct: IActiveProduct;
  hasSelection: boolean;
  beforeSelection: boolean;
  checkSelection: boolean;
  schema = {};
  invalidLinkImageSrc = "assets/image/invalid_link.png";
  invalidLink: boolean;
  starIcons = [];
  recentProducts: any[] = [];
  recentReviews: IReviewsData;

  upgradeProducts: IUpgradeProduct[];
  otherPeopleProducts = [];

  mainProductImage = [];
  serverProductImagesWithIndex = [];

  showSku = false;
  productSku = "";

  paramSku = "";
  pKey: string;
  updatedPrice: any;
  slideIndex = 0;
  newVariationFound: boolean;
  newVariationData: any;
  dupProduct: IProduct;
  prodBoardList: any[] = [];
  perRowCount: number;
  isPriceUpdate: boolean;
  brandCoverImg: any;
  newSku: string = "";
  salePriceStrike: number;
  onlySkuDetails: boolean;
  appUrl = '';
  serverProductGalleryImagesWithIndex: { image: string; index: number; }[];
  nos: number;
  assetBaseUrl: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  showBanner:boolean =false;
  isHandset: boolean;
  constructor(
    private apiService: ApiService,
    public utils: UtilsService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private location: Location,
    private matDialogUtils: MatDialogUtilsService,
    private seoService: SeoService,
    private pixelService: PixelService, 
    private router: Router,
    private activeRoute: ActivatedRoute, 
    private cookie: CookieService, 
    public env: EnvService,
    private _renderer2: Renderer2,
    private eventEmitterService:EventEmitterService
  ) {
    this.appUrl = this.env.appUrl;
    this.utils.showCatNav =false;
    this.utils.isHandset().subscribe(handset=>{
      this.isHandset = handset.matches;
    });
  }
getmsg(d){
 return this.utils.compileMarkdownText(d) 
}
ngOnInit(): void {
  this.assetBaseUrl = this.env.assetBaseUrl;

  this.desktopImageUrl =
    this.assetBaseUrl + "site/prompts/NewSubSignUpMini.png";
  this.mobileImageUrl =
    this.assetBaseUrl + "site/prompts/NewSubSignUpBlankSq.png";
  if (window.innerWidth > 1500) {
    this.nos = 10;
  } else if (window.innerWidth > 1200) {
    this.nos = 8;
  } else {
    this.nos = 6;
  }
  this.isProductFetching = true;
  this.loadProduct();

  this.eventSubscription = this.eventEmitterService.userChangeEvent
  .asObservable()
  .subscribe((user) => {
    if(this.isUserLoggedIn){
    this.router.navigate([`product/${this.paramSku}`]);}

  });
}
get isUserLoggedIn(): boolean {
  if (JSON.parse(localStorage.getItem("user"))) {
    return (
      JSON.parse(localStorage.getItem("user")) &&
      JSON.parse(localStorage.getItem("user")).email &&
      JSON.parse(localStorage.getItem("user")).email.length > 0
    );
  } else {
    return false;
  }
}
  

 

 

  getProdImgBoard() {
    this.apiService.getProdImgBoard(this.paramSku).subscribe((data: any) => {
      this.prodBoardList = data.sort((a, b) => a.serial - b.serial);
      this.perRowCount = Math.ceil(this.prodBoardList.length / 4);
    });
  }
  getBrandCoverImage(prd) {
    this.apiService.getBrandData(prd.brand).subscribe((data: any) => {
      if (data.length > 0) { 
        this.brandCoverImg = data[0].cover_image;
      }
    });
  }

  private loadProduct() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.pKey = params.pkey;
    });
    this.routeSubscription = this.activeRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.paramSku = params.id;
        this.isProductFetching = true;

        this.getProdImgBoard();
        this.productSubscription = this.apiService
          .getProduct(this.paramSku)
          .subscribe(
            (payload: IProductDetail) => {
              if (payload.product) {
                this.getBrandCoverImage(payload.product);
                const user = JSON.parse(localStorage.getItem("user"));
                if(payload.product.displayable == "true"){

                } else if (payload.product.displayable == "false") {
                  this.router.navigate(["/"]);
                  return;
                } else if (
                  payload.product.displayable == "seeded" &&
                  user &&
                  (''+user.authorization).charAt(0) !== '9' &&
                  (''+user.authorization).charAt(0) !== '7'
                ) {
                  this.router.navigate(["/"]);
                  return;
                }  else if (
                  payload.product.displayable == "authorized" &&
                  user &&
                  ((''+user.authorization).charAt(0) !== '9' &&
                  (''+user.authorization).charAt(0) !== '7' && (''+user.authorization)!== '2')
                ) {
                  this.router.navigate(["/"]);
                  return;
                }  else if (
                  payload.product.displayable == "preview" &&
                  user &&
                  (''+user.authorization) == '0'
                ) {
                  this.router.navigate([`product/${this.paramSku}/preview`]);
                  return;
                } else if (
                  payload.product.displayable == "restricted" &&
                  user &&
                  (''+user.authorization) == '0'
                ) {
                  // this.router.navigate([`product/${this.paramSku}/restricted`]);
                  // return;
                }
                this.processProduct(payload); 
                this.loadRecentProducts();
              }
            },
            (error) => {
              this.invalidLink = true;
              this.isProductFetching = false;
            }
          );
       
      }
    }); 
  }

  goBack() {
    this.location.back();
  }

  loadRecentProducts() {
    this.apiService
      .getRecentProducts()
      .pipe(first())
      .subscribe((response: any[]) => {
        this.recentProducts = response;
      });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } 

  private processProduct(payload: IProductDetail) {
    this.product = payload.product;
    this.dupProduct = JSON.parse(JSON.stringify(payload.product));
    if (
      this.product.variations.length === 0 &&
      this.product.product_status === "active"
    ) {
      this.isSetItemInInventory = true;
    }
    this.seoData = payload.seo_data;
    this.pixelService.trackViewContent(this.product);
    if (this.product.sku !== this.paramSku) {
      this.router.navigateByUrl("/product/" + this.product.sku);
    }
    this.upgradeProducts = payload.upgrades || []; 
    if (payload.product) {
      this.setProduct(payload); 
    } else {
      this.invalidLink = true;
    }
    this.isProductFetching = false;
    if (
      Math.floor(this.product.min_price) ==
        Math.floor(this.product.max_price) ||
      Math.floor(this.product.min_price) >=
        Math.floor(this.product.min_was_price)
    ) {
      this.updatedPrice = JSON.parse(JSON.stringify(this.product.min_price));
    } else {
      this.updatedPrice = JSON.parse(JSON.stringify(this.product.max_price));
    }
 
  }

  private setProduct(payload: IProductDetail) {
    this.setSeoData(payload);

    this.mainProductImage = [];
    this.mainProductImage.push(this.product.main_image); 
  }
 
 
  private setSeoData(payload: any) { 
    this.schema = payload.seo_data.json_ld;  
    const seoData: any = payload.seo_data;
    const metaData = {
      title: `${seoData.brand} ${seoData.product_name} | ${this.env.appName}`,
      description: seoData.description,
      image: seoData.image_url,
    };
    payload.seo_data.title = `${seoData.brand} ${seoData.product_name} | ${this.env.appName}`;
    this.seoService.setMetaTags(payload.seo_data,this._renderer2);
    this.seoService.setJsonLd(this._renderer2,this.schema);
  }

  onDestroy(): void {
    this.productSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

 

  

  isArray(obj: any) {
    return Array.isArray(obj);
  }
 
  openLink(event, url) {
    event.preventDefault();
    if (typeof vglnk) {
      vglnk.open(url, "_blank");
    }
  } 
      get sideWidth():number{
        return document.getElementById('sideSec').clientWidth;
      }

 
  scrollTo(elementId) {
    const el: Element = document.getElementById(elementId);
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  }
    
  selectTab(tab) {
    this.activeTab = tab;
  }
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
  showInterCom() {
    (<any>window).Intercom(
      "showNewMessage",
      "Hi there! I see you would like to chat with a design consultant."
    );
  }  
openSignIn(){
  this.matDialogUtils.openSignupDialog();
}
get imgWidth(){
  return document.getElementById('mpImg')?.clientWidth;
}
get imgHeight(){
  return document.getElementById('mpImg')?.clientHeight;
} 
}