import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services';

@Component({
  selector: 'app-design-proposals',
  templateUrl: './design-proposals.component.html',
  styleUrls: ['./design-proposals.component.less']
})
export class DesignProposalsComponent implements OnInit {
  @Input() isHandset: boolean;
  @Input() projectImages: any[] = [];
  fileList: any[] = [];
  apiCount: number;
  projectId: any;
  loading: boolean;
  imageList: any[] = [];
  currentTab = 'Idea Board';
  ideaImages: any[] = [{}, {}, {}, {}];
  designsecondCol: any[] = [];
  designfirstCol: any[] = [];
  designthirdCol: any[] = [];
  designImages: any[] = [];
  conceptImages: any[] = [];
  conceptfirstCol: any[] = [];
  conceptsecondCol: any[] = [];
  ideafirstCol: any[] = [];
  ideasecondCol: any[] = [];
  ideathirdCol: any[] = [];
  imageResponse: any[] = [];
  ideaSlider1: number = 0;
  ideaSlider2: boolean;

  ideaTitle: string = '';
  ideaNotes: any;
  ideaFileList: any[] = [];
  @ViewChild('confirmation') confirmation: TemplateRef<any>;
  currentItem: any;
  conceptFileList: any[] = [];
  designFileList: any[] = [];
  selectAll = false;

  constructor(private api: ApiService, private snackbar: MatSnackBar, private actRou: ActivatedRoute,
    private dialog: MatDialog,
  ) { }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.projectImages && changes.projectImages.currentValue) {
      this.projectImages = changes.projectImages.currentValue;
      this.takeImages();
    }
  }
  takeImages() {
    if (this.projectImages.length > 0) {
      this.ideaImages = [];

      this.ideaImages = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'idea')[0]?.images;
      this.conceptImages = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'concept')[0]?.images;
      this.designImages = this.projectImages[0]?.design_deliverables.filter(f => f.type === 'design')[0]?.images;

      this.ideaImages = (this.ideaImages && this.ideaImages.length > 0) ? this.ideaImages : [];
      this.conceptImages = (this.conceptImages && this.conceptImages.length > 0) ? this.conceptImages : [];
      this.designImages = (this.designImages && this.designImages.length > 0) ? this.designImages : [];

      this.ideaImages.forEach(f => f['checked'] = false);
      this.conceptImages.forEach(f => f['checked'] = false);
      this.designImages.forEach(f => f['checked'] = false);
    }
  }
  changeTab(t: string) {
    this.currentTab = t;
  }
  ngOnInit(): void {
    this.api.projectId = this.actRou.snapshot.params.id;
  }

  // download() {
  //   let finalImages = [];

  //   this.ideaImages.forEach(f=>{
  //     finalImages.push({image:f.image})
  //   });

  //   this.conceptImages.forEach(f=>{
  //     finalImages.push({image:f.image})
  //   });

  //   this.designImages.forEach(f=>{
  //     finalImages.push({image:f.image})
  //   });


  //   finalImages.forEach((f) => {
  //     if (f.image) {
  //       const imageUrl =
  //         `/${this.api.projectId}/client_submission/` +
  //         f.image.split(`/${this.api.projectId}/client_submission/`)[1];
  //       this.getImages(
  //         f.image,
  //         f.image.split(`/${this.api.projectId}/client_submission/`)[1],
  //         finalImages
  //       );
  //     }
  //   });
  // }
  // getImages(data: any, name: string, fullData: any[]) {
  //   this.api.getImageBlob(data).then((res) => {
  //     this.imageResponse.push({ name: name, content: res });
  //     if (fullData.length === this.imageResponse.length) {
  //       // this.downloadZip();
  //     }
  //   });
  // }
  // async downloadZip() {
  //   const files = this.imageResponse;

  //   const zip = new JSZip();

  //   for (const file of files) {
  //     zip.file(file.name, file.content);
  //   }

  //   const zipBlob = await zip.generateAsync({ type: "blob" });

  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(zipBlob);
  //   link.download = `${this.api.projectId}_documents.zip`;
  //   link.click();
  // }

  openFullScreen(event: MouseEvent): void {
    const imageElement = event.target as HTMLImageElement;

    // Create a div to cover the screen and display the image
    const fullScreenImage = document.createElement('img');
    fullScreenImage.src = imageElement.src;
    fullScreenImage.className = 'full-screen-image';

    // Append the image to the body
    document.body.appendChild(fullScreenImage);

    // Make the image full-screen
    if (fullScreenImage.requestFullscreen) {
      fullScreenImage.requestFullscreen();
    } else if (fullScreenImage['webkitRequestFullscreen']) { // Safari
      fullScreenImage['webkitRequestFullscreen']();
    } else if (fullScreenImage['msRequestFullscreen']) { // IE/Edge
      fullScreenImage['msRequestFullscreen']();
    }

    // Add a close functionality to exit fullscreen
    fullScreenImage.onclick = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
      } else if (document['msExitFullscreen']) {
        document['msExitFullscreen']();
      }
      fullScreenImage.remove();
    };
  }

  uploadDoc(event: any, type: string) {
    let reader = new FileReader();
    if (event.target.files) {
      if (this.currentTab === 'Idea Board') {
        for (let i = 0; i < event.target.files.length; i++) {

          this.ideaFileList.unshift(event.target.files[i]);


          reader = new FileReader();

          reader.readAsDataURL(event.target.files[i]); // read file as data url

          reader.onload = (x: any) => {
            this.ideaImages.unshift({ id: "0", image: x.target.result, checked: true });


            if (event.target.files.length === i + 1) {
              this.addImage('idea', this.ideaFileList);
            }
          };
        }
      } else if (this.currentTab == 'Concept Boards') {
        for (let i = 0; i < event.target.files.length; i++) {

          this.conceptFileList.unshift(event.target.files[i]);


          reader = new FileReader();

          reader.readAsDataURL(event.target.files[i]); // read file as data url

          reader.onload = (x: any) => {
            this.conceptImages.unshift({ id: "0", image: x.target.result, checked: true });

            if (event.target.files.length === i + 1) {
              this.addImage('concept', this.conceptFileList);
            }
          };
        }
      } else if (this.currentTab == 'Room Design') {
        for (let i = 0; i < event.target.files.length; i++) {

          this.designFileList.unshift(event.target.files[i]);


          reader = new FileReader();

          reader.readAsDataURL(event.target.files[i]); // read file as data url

          reader.onload = (x: any) => {
            this.designImages.unshift({ id: "0", image: x.target.result, checked: true });

            if (event.target.files.length === i + 1) {
              this.addImage('design', this.designFileList);
            }
          };
        }
      }
    }
  }
  addImage(type: string, list: any[]) {
    let count = 0;
    this.apiCount = 0;
    if (list.length > 0) {
      count++;
    }
    if (list.length > 0) {
      let formData = new FormData();

      list.forEach((e, index) => {
        formData.append("image" + (index + 1), e);
      });
      formData.append("type", type);
      formData.append("count", "" + list.length);

      this.save(formData, count);
    }

  }

  save(formData, count) {
    formData.append("project_id", this.api.projectId);

    this.loading = true;
    this.api.saveDesignDeliverables(formData).subscribe(
      (res: any) => {
        if (res.status) {
          this.apiCount++;
          if (count == this.apiCount) {
            this.loading = false;
            this.snackbar.open("Image Uploaded Successfully", "x", {
              duration: 3000,
            });
            this.getDesignImage()
          }
        } else {
          this.loading = false;
          this.snackbar.open("Image Upload Failed, Please try again!", "x", {
            duration: 3000,
          });
        }
      },
      (err) => {
        this.loading = false;
        this.snackbar.open("Image Upload Failed, Please try again!", "x", {
          duration: 3000,
        });
      }
    );
  }

  getDesignImage() {
    this.api.getDesignProject(this.api.projectId).subscribe((resp: any) => {
      if (resp && resp.length > 0) {
        this.projectImages = resp;
        this.takeImages();
      }
    });
  }
  check(idea) {
    // if(!idea.is_published){
    idea.checked = true
    // }
  }

  openConfirmation(data) {
    this.dialog.open(this.confirmation, {
      disableClose: true,
      width: '34em'
    })
    //   this.currentItem = data;
    //   if(!data.is_published){
    //   this.dialog.open(this.confirmation,{
    //     disableClose:true,
    //     width:'34em'
    //   })
    // }
  }

  selectUnSelect(event) {

    if (this.currentTab === 'Idea Board') {
      this.ideaImages.forEach(f => f['checked'] = event.checked);
    } else if (this.currentTab == 'Concept Boards') {
      this.conceptImages.forEach(f => f['checked'] = event.checked);
    } else {
      this.designImages.forEach(f => f['checked'] = event.checked);

    }
  }

  get getSelections(): boolean {
    if (this.currentTab === 'Idea Board') {
      return this.ideaImages.some(f => f['checked']);
    } else if (this.currentTab == 'Concept Boards') {
      return this.conceptImages.some(f => f['checked']);
    } else {
      return this.designImages.some(f => f['checked']);

    }
  }

  saveChanges() {
    this.noOfTimescalled = 0;
    this.ids= [];
    if (this.currentTab === 'Idea Board') {
      this.ideaImages.forEach(f => { if (f.checked) { this.updateDeliverables(f) } });
    } else if (this.currentTab == 'Concept Boards') {
      this.conceptImages.forEach(f => { if (f.checked) { this.updateDeliverables(f) } });
    } else {
      this.designImages.forEach(f => { if (f.checked) { this.updateDeliverables(f) } });
    }
  }
  publishDeliverables() {
    let data = {
      "project_id": this.api.projectId,
      "deliverables": this.ids.join(',')
    }
    this.api.publisDesignDeliverables(data).subscribe((res: any) => {
      if (res.status) {
        this.snackbar.open('Published successfully', 'x', { duration: 1000 })

      }
    })
  }
  noOfTimescalled = 0;
  ids: any[] = [];
  updateDeliverables(idea, count = 0) {

    let data = {
      title: idea.title,
      note: idea.note,
      is_published: idea.is_published,
      id: idea.id,
      project_id: this.api.projectId
    }
    this.api.updateDesignDeliverables(data).subscribe((res: any) => {
      this.noOfTimescalled++;
      this.ids.push(idea.id);
      if (count > 0 && count === this.noOfTimescalled && res.status) {
        this.publishDeliverables();
      }
      this.snackbar.open('Saved successfully', 'x', { duration: 1000 })
    })

  }

  setToggleOn() {

    this.noOfTimescalled = 0;
    this.ids= [];
    // this.currentItem.is_published = true;
    if (this.currentTab === 'Idea Board') {
      let count = this.ideaImages.filter(f => f.checked).length;
      this.ideaImages.forEach((f) => {
        if (f.checked) {
          f.is_published = true;
          this.updateDeliverables(f, count);
        }
      }
      );
    } else if (this.currentTab == 'Concept Boards') {
      let count = this.conceptImages.filter(f => f.checked).length;

      this.conceptImages.forEach(f => {
        if (f.checked) {
          f.is_published = true;
          this.updateDeliverables(f, count);
        }
      });
    } else {
      this.designImages.forEach(f => {
        let count = this.designImages.filter(f => f.checked).length;

        if (f.checked) {
          f.is_published = true;
          this.updateDeliverables(f, count);
        }
      });
    }
    this.dialog.closeAll();
  }

  setToggleOff() {
    // this.currentItem.is_published = false;
    this.dialog.closeAll();
  }

  error() {
    return `<div class="skeleton-load skeleton-text"></div>`
  }

}
