import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import {WishlistSnackbarComponent} from '../../components/wishlist-snackbar/wishlist-snackbar.component'; 
import {BreakpointObserver, BreakpointState, Breakpoints} from '@angular/cdk/layout'; 
import { EventEmitterService } from '../events/event-emitter.service';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { SignupComponent } from 'src/app/core/components/signup/signup.component';

@Injectable({
    providedIn: 'root'
})
export class WishlistSnackbarService {

    isHandset = false;
    guestWishlistedSku = [];
    readonly userType = {
        // guest: 0,
        // default: 1,
        // admin: 90,
        // admin1: 70,
        
        guest: 0,
        registered: 1,
        verified: 2,
        adminFullSite: 90 ,
        adminDesign: 70,
    };

    bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
        Breakpoints.Handset
    );
    bpSubscription: Subscription; 

    constructor(
        private snackBar: MatSnackBar, 

        public dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private eventEmitterService: EventEmitterService,
        private apiService: ApiService,
    ) {
        this.bpObserver.subscribe((handset: BreakpointState) => {
            this.isHandset = handset.matches;
        });
    }

    addToWishlist(productSku) {
        const user = this.getCurrentUser();
        if (user.authorization === this.userType.guest) {
            this.guestWishlistedSku.push(productSku);
            this.openGuestSnackbar();
        } else {
            this.openUserSnackbar();
        }

    }

    openGuestSnackbar() {
        const snackBarRef = this.snackBar.openFromComponent(WishlistSnackbarComponent, {
            panelClass: this.isHandset ? 'snackbar-container-handset' : 'snackbar-container',
            data: 'guest',
            duration: 5000
        });
        snackBarRef.onAction().subscribe(() => {
            this.openSignupDialog(this.isHandset, true);
            this.eventEmitterService.userChangeEvent.asObservable().subscribe(user => {
                if (user.authorization > 0) {
                    this.markProductWishlisted();
                }
            });
        });
    }
    openSignupDialog(isHandset = false, isClose = false) {
        const width = isHandset ? '100%' : '35%';
        // tslint:disable-next-line: no-unused-expression
        !isClose && this.dialog.closeAll();
        return this.dialog.open(SignupComponent, {
            width,
            data: {isClose},
            panelClass: 'auth-dialog-container',
            autoFocus: false,
        });
    }

    markProductWishlisted() {
        this.guestWishlistedSku.forEach(sku => {
            this.apiService
                .wishlistProduct(sku, true, this.isHandset).subscribe(value => {
            });
        });
        this.guestWishlistedSku = [];
    }

    openUserSnackbar() {
        const snackBarRef = this.snackBar.openFromComponent(WishlistSnackbarComponent, {
            panelClass: this.isHandset ? 'snackbar-container-handset' : 'snackbar-container',
            data: 'user',
            duration: 2000
        });
    }

    removeIfExistsProduct(sku) {
        const index = this.guestWishlistedSku.indexOf(sku);
        if (index !== -1) {
            this.guestWishlistedSku.splice(index, 1);
        }
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
}
