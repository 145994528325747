import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaymentComponent } from './payment.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { NotEligibleDialogComponent } from './not-eligible-dialog/not-eligible-dialog.component';
import { NotAvailableDialogComponent } from './not-available-dialog/not-available-dialog.component';
import { CarouselModule } from 'primeng/carousel';

const DECLARATIONS = [PaymentComponent];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MatIconModule,
  CarouselModule,
  SharedModule
];

@NgModule({
  declarations: [...DECLARATIONS, NotEligibleDialogComponent, NotAvailableDialogComponent],
  imports: [...MODULES],
  entryComponents:[NotEligibleDialogComponent,NotAvailableDialogComponent],
  exports: [...DECLARATIONS, ...MODULES]
})
export class PaymentModule {}
