import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IFilterData } from "src/app/shared/models";
import { Options } from "@angular-slider/ngx-slider";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-product-filters",
  templateUrl: "./product-filters.component.html",
  styleUrls: ["./product-filters.component.less"],
})
export class ProductFiltersComponent implements OnInit, OnChanges {
  @Output() setFilters = new EventEmitter<any>();
  @Input() isBrandPage = false;
  @Input() isCollectionPage = false;
  @Input() count = 0;
  @Input() recommendedList = [];
  @Input() sortType = "";
  @Output() setSortType = new EventEmitter<any>();
  typeChecked = false;
  @Input() productFilters: IFilterData = {
    brand: [],
    price: {
      from: 0,
      to: 0,
      max: 0,
      min: 0,
    },
    type: [],
    color: [],
    shape: [],
    seating: [],
    country: [],
    designer: [],
    fabric: [],
    material: [],
    width: [],
    depth: [],
    height: [],
    weight: [],
    length: [],
    subcategory:[],
    // diameter: [],
    square: [],
    category: [],
    collection: [],
    collection_beta: [],
    collection_omega: [],
  };
  @Input() isChangingBrandList = false;
  objectKeys = Object.keys;
  isClearAllVisible = false;
  dimensionFilterKeysToExclude = [
    "height",
    "width",
    "length",
    "square",
    "depth",
    "weight",
  ];
  activeFilters = {
    // brand: [],
    collection: [],
    collection_beta: [],
    collection_omega: [],
    price_from: 0,
    price_to: 0,
    type: [],
    height_from: 0,
    height_to: 0,
    width_from: 0,
    width_to: 0,
    length_from: 0,
    length_to: 0,
    diameter_from: 0,
    diameter_to: 0,
    square_from: 0,
    square_to: 0,
    depth_from: 0,
    depth_to: 0,
    color: [],
    category: [],
    shape: [],
    seating: [],
    country: [],
    designer: [],
    fabric: [],
    material: [],
    style: [],
  };
  isPriceChanged = false;
  priceFilter = {
    name: "price",
    from: 100,
    to: 600,
    min: 100,
    max: 600,
  };
  sliderOptions: Options = {
    step: 1,
  };
  isSizeChanged = false;
  // Dimension Filters Display
  displayDimensionFilter: any = [];
  tempFilters: string;
  expandFilter: boolean;
  showZipCodeBox: boolean;
  selectedTypeItem: any;

  tabletSubscription: Subscription;
  isTablet = false;

  tabletObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Tablet
  );
  showApply: boolean;

  constructor(
    private activeRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.setClearButtonVisibility(params.filters);
    });
    // Get Dimension filters in another variable. To show them separately.
    this.populateDimensionFilters();

    this.tabletSubscription = this.tabletObserver.subscribe(
      (tablet: BreakpointState) => {
        this.isTablet = tablet.matches;
      }
    );
  }

  populateDimensionFilters() {
    this.displayDimensionFilter = [];
    for (const filter in this.productFilters) {
      if (this.dimensionFilterKeysToExclude.includes(filter)) {
        this.displayDimensionFilter.push(this.productFilters[filter][0]);
      }
    }
  }

  trackByFn(index, item) {
    return item.name;
  }

  private setClearButtonVisibility(filters) {
    if (this.isBrandPage || this.isCollectionPage) {
      if (filters) {
        const filtersArray = filters.split(";").filter((value) => value);
        if (filtersArray.length <= 0) {
          this.isClearAllVisible = false;
        } else {
          this.isClearAllVisible = true;
        }
      }
    } else {
      this.isClearAllVisible = !!filters;
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(change: any) {
    if (
      change.isChangingBrandList &&
      change.isChangingBrandList.currentValue === true
    ) {
      this.isPriceChanged = false;
      this.activeFilters = {
        // brand: this.activeFilters.brand,
        collection: [],
        collection_beta: [],
        collection_omega: [],
        price_from: 0,
        price_to: 0,
        type: [],
        height_from: 0,
        height_to: 0,
        width_from: 0,
        width_to: 0,
        length_from: 0,
        length_to: 0,
        diameter_from: 0,
        diameter_to: 0,
        square_from: 0,
        square_to: 0,
        depth_from: 0,
        depth_to: 0,
        color: [],
        category: [],
        shape: [],
        seating: [],
        country: [],
        designer: [],
        fabric: [],
        material: [],
        style: [],
      };
    } else {
      if (
        change.productFilters &&
        change.productFilters.currentValue !== undefined
      ) {
        if (this.productFilters.material) {
          const keys = Object.keys(this.productFilters.material);
          let newData = [];
          keys.forEach((f) => {
            newData.push({ name: f, values: this.productFilters.material[f] });
          });
          this.productFilters.material = newData;
        }
 
        if (this.isBrandPage) {
          this.productFilters = this.moveToFront(
            change.productFilters.currentValue,
            "category"
          );
        } else {
          this.productFilters = change.productFilters.currentValue;
        }
        if (this.isCollectionPage) {
          // this.activeFilters.collection = this.productFilters.collection;
          this.activeFilters.collection = this.productFilters.collection_omega;
        } else {
          // delete this.productFilters.collection;
          delete this.productFilters.collection_omega;
        }
        this.clearEmptyFilters();
        this.populateDimensionFilters();
        if (this.productFilters && !this.isPriceChanged) {
          this.priceFilter = {
            ...this.priceFilter,
            ...this.productFilters.price,
          };
          this.activeFilters.price_from = this.priceFilter.min;
          this.activeFilters.price_to = this.priceFilter.max;
          // this.activeFilters.brand = this.productFilters.brand && this.productFilters.brand
          //     .filter((brand) => brand.checked)
          //     .map((brand) => brand.value);
          this.activeFilters.type = this.productFilters.type
            .filter((type) => type.checked)
            .map((type) => type.value);
          this.activeFilters.color = this.productFilters.color
            .filter((color) => color.checked)
            .map((color) => color.value);
          if (this.productFilters.seating) {
            this.activeFilters.seating = this.productFilters.seating
              .filter((seating) => seating.checked)
              .map((seating) => seating.value);
          }
          if (this.productFilters.shape) {
            this.activeFilters.shape = this.productFilters.shape
              .filter((shape) => shape.checked)
              .map((shape) => shape.value);
          }
          if (this.productFilters.category) {
            this.activeFilters.category = this.productFilters.category
              .filter((category) => category.checked)
              .map((category) => category.value);
          }
          if (this.productFilters.country) {
            this.activeFilters.country = this.productFilters.country
              .filter((mfgCountry) => mfgCountry.checked)
              .map((mfgCountry) => mfgCountry.value);
          }
          if (this.productFilters.designer) {
            this.activeFilters.designer = this.productFilters.designer
              .filter((designer) => designer.checked)
              .map((designer) => designer.value);
          }
          if (this.productFilters.fabric) {
            this.activeFilters.fabric = this.productFilters.fabric
              .filter((fabric) => fabric.checked)
              .map((fabric) => fabric.value);
          }
          if (this.productFilters.material) {
            // const keys = Object.keys(this.productFilters.material);
            // let newData = [];
            // keys.forEach(f => {
            //     newData.push({ name: f, values: this.productFilters.material[f] })
            // })
            // this.productFilters.material = newData;
            this.productFilters.material.forEach((f) => {
              f.values
                .filter((fi) => fi.checked)
                .forEach((m) => this.activeFilters.material.push(m.value));
            });
            // this.activeFilters.material = pf
            //     .filter((material) => material.checked)
            //     .map((material) => material.value);
          }
          this.isSizeChanged = false;
          if (this.productFilters.height && this.productFilters.height[0]) {
            const filter = this.productFilters.height[0];
            if (
              Math.round(filter.min) !== Math.round(filter.from) ||
              Math.round(filter.max) !== Math.round(filter.to)
            ) {
              const from = `${filter.name.toLowerCase()}_from`;
              const to = `${filter.name.toLowerCase()}_to`;
              this.activeFilters[from] = filter.from;
              this.activeFilters[to] = filter.to;
              this.isSizeChanged = true;
            }
          }
          if (this.productFilters.width && this.productFilters.width[0]) {
            const filter = this.productFilters.width[0];
            if (
              Math.round(filter.min) !== Math.round(filter.from) ||
              Math.round(filter.max) !== Math.round(filter.to)
            ) {
              const from = `${filter.name.toLowerCase()}_from`;
              const to = `${filter.name.toLowerCase()}_to`;
              this.activeFilters[from] = filter.from;
              this.activeFilters[to] = filter.to;
              this.isSizeChanged = true;
            }
          }
          if (this.productFilters.diameter && this.productFilters.diameter[0]) {
            const filter = this.productFilters.diameter[0];
            if (
              Math.round(filter.min) !== Math.round(filter.from) ||
              Math.round(filter.max) !== Math.round(filter.to)
            ) {
              const from = `${filter.name.toLowerCase()}_from`;
              const to = `${filter.name.toLowerCase()}_to`;
              this.activeFilters[from] = filter.from;
              this.activeFilters[to] = filter.to;
              this.isSizeChanged = true;
            }
          }
          if (this.productFilters.square && this.productFilters.square[0]) {
            const filter = this.productFilters.square[0];
            if (
              Math.round(filter.min) !== Math.round(filter.from) ||
              Math.round(filter.max) !== Math.round(filter.to)
            ) {
              const from = `${filter.name.toLowerCase()}_from`;
              const to = `${filter.name.toLowerCase()}_to`;
              this.activeFilters[from] = filter.from;
              this.activeFilters[to] = filter.to;
              this.isSizeChanged = true;
            }
          }
          if (this.productFilters.depth && this.productFilters.depth[0]) {
            const filter = this.productFilters.depth[0];
            if (
              Math.round(filter.min) !== Math.round(filter.from) ||
              Math.round(filter.max) !== Math.round(filter.to)
            ) {
              const from = `${filter.name.toLowerCase()}_from`;
              const to = `${filter.name.toLowerCase()}_to`;
              this.activeFilters[from] = filter.from;
              this.activeFilters[to] = filter.to;
              this.isSizeChanged = true;
            }
          }
          // if (this.productFilters.height) {
          //     const activeFilterValues = this.productFilters.height[0].values
          //         .filter((enabled) => enabled.checked);
          //     this.activeFilters.height = activeFilterValues.map(value => this.renderOptions(value));
          //     this.activeFilters.height_from = activeFilterValues.map(value => value.min);
          //     this.activeFilters.height_to = activeFilterValues.map(value => value.max);
          // }
          // if (this.productFilters.width) {
          //     const activeFilterValues = this.productFilters.width[0].values
          //         .filter((enabled) => enabled.checked);
          //     this.activeFilters.width = activeFilterValues.map(value => this.renderOptions(value));
          //     this.activeFilters.width_from = activeFilterValues.map(value => value.min);
          //     this.activeFilters.width_to = activeFilterValues.map(value => value.max);
          // }
          // if (this.productFilters.diameter) {
          //     const activeFilterValues = this.productFilters.diameter[0].values
          //         .filter((enabled) => enabled.checked);
          //     this.activeFilters.diameter = activeFilterValues.map(value => this.renderOptions(value));
          //     this.activeFilters.diameter_from = activeFilterValues.map(value => value.min);
          //     this.activeFilters.diameter_to = activeFilterValues.map(value => value.max);
          // }
          // if (this.productFilters.square) {
          //     const activeFilterValues = this.productFilters.square[0].values
          //         .filter((enabled) => enabled.checked);
          //     this.activeFilters.square = activeFilterValues.map(value => this.renderOptions(value));
          //     this.activeFilters.square_from = activeFilterValues.map(value => value.min);
          //     this.activeFilters.square_to = activeFilterValues.map(value => value.max);
          // }
          // if (this.productFilters.depth) {
          //     const activeFilterValues = this.productFilters.depth[0].values
          //         .filter((enabled) => enabled.checked);
          //     this.activeFilters.depth = activeFilterValues.map(value => this.renderOptions(value));
          //     this.activeFilters.depth_from = activeFilterValues.map(value => value.min);
          //     this.activeFilters.depth_to = activeFilterValues.map(value => value.max);
          // }
        }
        if (
          Math.round(this.productFilters.price.from) ===
            Math.round(this.productFilters.price.min) &&
          Math.round(this.productFilters.price.to) ===
            Math.round(this.productFilters.price.max)
        ) {
          this.isPriceChanged = false;
          if (this.activeFilters.price_from) {
            delete this.activeFilters.price_from;
          }
          if (this.activeFilters.price_to) {
            delete this.activeFilters.price_to;
          }
        } else {
          this.isPriceChanged = true;
        }
      }
    }
  }
  moveToFront = (obj: any, prop: string) => {
    const newObj: any = { [prop]: obj[prop] };
    for (const key in obj) {
      if (key !== prop) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };
  selectType(option) {
    this.selectedTypeItem = option.name;
  }
  onCheckChange(event, filter: string, materialOption?: any) {
    this.showApply = true;
    const option = event.source.value;
    if (this.productFilters[filter][0].values && filter !== "material") {
      this.setDimensionFilters(event, filter);
    } else if (filter == "material") {
      if (event.source.checked) {
        this.productFilters.material.forEach((f) => {
          if (materialOption.name == f.name) {
            const val = f.values.find(
              (d) => d.value == event.source.value.value
            );
            if (val) {
              val.checked = true;
              // val.enabled = true;
              this.activeFilters[filter].push(option.value);
            }
          }
        });
      } else {
        const optionsArr = this.activeFilters[filter].filter(
          (value: string) => value !== option.value
        );
        this.activeFilters[filter] = optionsArr;
      }
    } else {
      if (event.source.checked) {
        this.activeFilters[filter].push(option);
      } else {
        const optionsArr = this.activeFilters[filter].filter(
          (value: string) => value !== option
        );
        this.activeFilters[filter] = optionsArr;
      }
    }
    if (
      Math.round(this.priceFilter.from) === this.productFilters.price.min &&
      Math.round(this.priceFilter.to) === this.productFilters.price.max
    ) {
      delete this.activeFilters.price_from;
      delete this.activeFilters.price_to;
      this.isPriceChanged = false;
    } else {
      this.isPriceChanged = true;
    }

    this.buildAndSetFilters(filter == "type" ? true : false);
  }

  setDimensionFilters(event, filter) {
    const values = event;
    const maxValueString = `${filter}_to`;
    const minValueString = `${filter}_from`;
    this.activeFilters[maxValueString] = values.maxValue;
    this.activeFilters[minValueString] = values.minValue;
    this.isSizeChanged = true;
    this.showApply = true;
    this.buildAndSetFilters();
  }

  clearFilters() {
    if (this.isBrandPage) {
      this.activeFilters = {
        // brand: this.activeFilters.brand,
        collection: [],
        collection_beta: [],
        collection_omega: [],
        price_from: 0,
        price_to: 0,
        type: [],
        height_from: 0,
        height_to: 0,
        width_from: 0,
        width_to: 0,
        length_from: 0,
        length_to: 0,
        diameter_from: 0,
        diameter_to: 0,
        square_from: 0,
        square_to: 0,
        depth_from: 0,
        depth_to: 0,
        color: [],
        category: [],
        shape: [],
        seating: [],
        country: [],
        designer: [],
        fabric: [],
        material: [],
        style: [],
      };
    } else if (this.isCollectionPage) {
      this.activeFilters = {
        // brand: [],
        collection: this.activeFilters.collection,
        collection_beta: [],
        collection_omega: this.activeFilters.collection,
        price_from: 0,
        price_to: 0,
        type: [],
        height_from: 0,
        height_to: 0,
        width_from: 0,
        width_to: 0,
        length_from: 0,
        length_to: 0,
        diameter_from: 0,
        diameter_to: 0,
        square_from: 0,
        square_to: 0,
        depth_from: 0,
        depth_to: 0,
        color: [],
        category: [],
        shape: [],
        seating: [],
        country: [],
        designer: [],
        fabric: [],
        material: [],
        style: [],
      };
    } else {
      this.activeFilters = {
        // brand: [],
        collection: [],
        collection_beta: [],
        collection_omega: [],
        price_from: 0,
        price_to: 0,
        type: [],
        height_from: 0,
        height_to: 0,
        width_from: 0,
        width_to: 0,
        length_from: 0,
        length_to: 0,
        diameter_from: 0,
        diameter_to: 0,
        square_from: 0,
        square_to: 0,
        depth_from: 0,
        depth_to: 0,
        color: [],
        category: [],
        shape: [],
        seating: [],
        country: [],
        designer: [],
        fabric: [],
        material: [],
        style: [],
      };
    }

    delete this.activeFilters.price_from;
    delete this.activeFilters.price_to;

    this.isPriceChanged = false;
    this.isSizeChanged = false;
    this.buildAndSetFilters(true);
    this.showApply = false;
    this.isClearAllVisible = false;
  }

  buildAndSetFilters(clearFlag?: boolean) {
    this.tempFilters = "";
    for (const [filter, options] of Object.entries(this.activeFilters)) {
      if (
        (filter === "price_from" || filter === "price_to") &&
        this.isPriceChanged
      ) {
        this.tempFilters += `${filter}:${options};`;
      } else {
        if (Array.isArray(options)) {
          this.tempFilters += options.length ? `${filter}:${options};` : ``;
        } else if (options !== 0) {
          this.tempFilters += `${filter}:${options};`;
        }
      }
    }
    if (clearFlag) {
      this.setFilters.emit(this.tempFilters);
    }

    // this.setFilters.emit(this.tempFilters);
    this.setClearButtonVisibility(this.tempFilters);
    return this.tempFilters;
  }
  applyFilters() {
    this.setFilters.emit(this.tempFilters);
    this.setClearButtonVisibility(this.tempFilters);
    this.showApply =false;
  }

  onPriceChange(event) {
    this.activeFilters.price_from = event.minValue;
    this.activeFilters.price_to = event.maxValue;
    this.isPriceChanged = true;
    this.buildAndSetFilters();
    this.showApply = true;
  }

  disableTab(filter) {
    if (filter !== "price") {
      return this.productFilters[filter].length === 0;
    } else {
      return false;
    }
  }

  isArray(value) {
    return Array.isArray(value);
  }

  renderOptions(option) {
    const maxValue = option.highValue;
    const minValue = option.value;
    return `${minValue}" - ${maxValue}"`;
  }

  // !((filter === 'brand' || filter === 'category') && (isBrandPage === true))
  checkIfValidFilter(filter): boolean {
    if (
      !this.dimensionFilterKeysToExclude.includes(filter) &&
      filter !== "price"
    ) {
      if (this.productFilters[filter].length === 0) {
        return false;
      }
    }

    if (filter === "brand") {
      if (this.isCollectionPage || this.isBrandPage) {
        return false;
      }
    }
    return true;
  }

  renderFilterName(filter): string {
    return filter.replace("_", " ").toUpperCase();
  }

  clearEmptyFilters() {
    // this.productFilters.collection_omega =[];
    for (const productFiltersKey in this.productFilters) {
      if (
        !this.dimensionFilterKeysToExclude.includes(productFiltersKey) &&
        productFiltersKey !== "price" &&
        productFiltersKey !== "material" &&
        productFiltersKey !== "category"
      ) {
        this.productFilters[productFiltersKey] = this.productFilters[
          productFiltersKey
        ].filter((value) => value.count > 0);
      } else if (productFiltersKey == "material") {
        const newVal: MaterialFilter[] = [];
        this.productFilters.material.forEach((f) => {
          f.values.forEach((fi, index) => {
            if (fi.enabled && fi.count > 0) {
              const ind = newVal.findIndex((fn) => fn.name == f.name);
              if (ind >= 0) {
                newVal[ind].values.push(fi);
              } else {
                newVal.push({ name: f.name, values: [fi] });
              }
            }
          });
        });
        this.productFilters.material = newVal;
      }
    }
  }
  expandMoreFlter() {
    this.expandFilter = !this.expandFilter;
  }
  onSetSortType(event) {
    this.setSortType.emit(event);
  }
}

export class MaterialFilter {
  name: string;
  values: any[];
}
