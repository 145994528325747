import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService, UtilsService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-booking-paypal",
  templateUrl: "./booking-paypal.component.html",
  styleUrls: ["./booking-paypal.component.less"],
})
export class BookingPaypalComponent implements OnInit {
  @Input() isHandset = false;

  logoPath = "";
  amount: number;
  projectId: string;
  bookForm: any;
  testimonialToShow: any;
  trustPilotStarsPath = "assets/image/trustpilot_stars.png";
  guarantee =
    "https://lazysuzy-images.s3.us-east-2.amazonaws.com/site/design/guarantee.png";

  initials: any;
  promo='';
  promoErr: string='';
  promoDetails: any;
  subtotal: number;
  constructor(
    private api: ApiService,
    private router: Router,
    public env: EnvService,
    private utils: UtilsService,
    private actRouter: ActivatedRoute
  ) {
    // this.getDesignPayment();
  }

  get deviceHeight(): number {
    return window.innerHeight;
  }

  get deviceWidth(): number {
    return window.innerWidth;
  }

  getPromoErr(e){
    this.promoDetails = null;
    if(e.error_msg){
    this.promoErr = e.error_msg;
    } else if(e.length===0){
      this.promoDetails = null;
    } else{
      this.promoDetails = e;
      this.subtotal = 79 - this.promoDetails.total_discount
    }
  }

  ngOnInit(): void {
    this.logoPath = this.env.logoPathWhite;

    window.scrollTo(0, 0);
    this.hideIntercomIcon();
    this.utils.isHandset().subscribe((handset) => {
      this.isHandset = handset.matches;
      if (this.deviceWidth<=900) {
        this.isHandset =true;
      }
    });
    // this.api.stripeDesignPayment().subscribe((res: any) => {
    //   this.amount = res.amount / 100;
    // });

    this.projectId = this.actRouter.snapshot.params.bookingId;

    this.api.getDesignTestimonials().subscribe((testimonials: any[]) => {
      const randomIdx = Math.round(Math.random() * 10) % testimonials.length;
      this.testimonialToShow = testimonials[randomIdx];

      const initials = this.testimonialToShow.user_name.match(/\b\w/g) || [];
      this.initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
    });
  }
  get isLoggedIn(): boolean {
    return (
      JSON.parse(localStorage.getItem("user")) &&
      JSON.parse(localStorage.getItem("user")).email.length > 0
    );
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      const intercomApp = document.getElementsByClassName("intercom-app")[0];

      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
      if (intercomApp) {
        intercomApp.classList.add("dn");
        clearInterval(interval);
      }
    }, 1000);
  }
  ngAfterViewInit(): void {
    this.hideIntercomIcon();
  }

  getDesignPayment() {
    this.api.designPayment().subscribe((res: any) => {
      this.amount = res.amount / 100;
    });
  }

  get boxWidth(): number {
    return document.getElementById("summaryBox")?.clientWidth;
  }
  applyPromo(){
    if(this.promo){
    this.promoErr = '';
    this.api.promoObserver$.next(this.promo);
    }
  }
  removePromo(){
    this.promo ='';
    this.api.promoObserver$.next('--Remove--');
  }
  
  execPayment(event) {
    if (event.status === "COMPLETED" || event.status === true) {
      this.router.navigate([`/design/projects/${this.projectId}/landing`]);
    }
    // let user = JSON.parse(localStorage.getItem("user"));
    //  let data = {
    //    f_Name: user.firstName,
    //    l_Name:user.lastName,
    //    email: user.email,
    //    payment_id: event.payment_id,
    //    payer_id: event.payer_id,
    //    status: event.status,
    //  };

    //  this.api.customerCreate(data).subscribe((res: any) => {
    //    if (res) {
    //     // this.router.navigate([`/design/projects/${this.projectId}/dashboard`]);
    //     this.router.navigate([`/design/projects/${this.projectId}/landing`]);
    //    }
    //  });
  }
}
