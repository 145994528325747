import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import { fromEvent, interval, merge, Subscription } from "rxjs";
import { debounce, filter, map } from "rxjs/operators";

@Component({
  selector: "app-range-filter",
  templateUrl: "./range-filter.component.html",
  styleUrls: ["./range-filter.component.less"],
})
export class RangeFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filter: any;
  @Input() hasUnit: boolean;
  @Input() unit = "";
  @Input() showLabel = true;
  @Input() sliderOptions: Options;
  @Input() goBtn:boolean;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() goEmit: EventEmitter<any> = new EventEmitter<any>();

  

  @ViewChild("min", { static: true }) min: ElementRef<any>;
  @ViewChild("max", { static: true }) max: ElementRef<any>;

  options: Options;
  minValue: number;
  maxValue: number;
  from: number;
  to: number;
  ceil: number;
  floor: number;

  allEvents$: Subscription;

  eventsToTrack = ["keyup", "blur"];
  prevMinValue = 0;
  prevMaxValue = 0;

  constructor() {}

  ngOnInit() {
    this.initializeInputs(this.filter);
    this.initializeInputListener();
  }

  valueChangedSlider(event) {

    const roundMin  = Math.ceil(Math.floor(event.value)/10)*10;
    const roundMax  = Math.ceil(Math.floor(event.highValue)/10)*10;
    const e = {
      minValue: roundMin,
      maxValue: roundMax,
    };
    this.minValue = JSON.parse(JSON.stringify(roundMin));
    this.maxValue = JSON.parse(JSON.stringify(roundMax));

    this.prevMinValue = this.from;
    this.prevMaxValue = this.to;
    this.valueChange.emit(e);
  }

  initializeInputs(rangeInputs: any) {
    if (rangeInputs && rangeInputs.hasOwnProperty("min") && rangeInputs.hasOwnProperty("max")) {
      this.floor = Math.ceil(Math.floor(rangeInputs.min)/10)*10;
      this.ceil = Math.ceil(Math.floor(rangeInputs.max)/10)*10;
      const defaultSliderOptions: Options = {
        floor: Math.ceil(Math.floor(rangeInputs.min)/10)*10,
        ceil: Math.ceil(Math.floor(rangeInputs.max)/10)*10,
        translate: (value, label) => {
          return "";
        },
        step: 10,
      };
      this.options = { ...defaultSliderOptions, ...this.sliderOptions };
      this.from = Math.ceil(Math.floor(rangeInputs.from)/10)*10;
      this.to = Math.ceil(Math.floor(rangeInputs.to)/10)*10;
      this.minValue = Math.ceil(Math.floor(rangeInputs.from)/10)*10;
      this.maxValue = Math.ceil(Math.floor(rangeInputs.to)/10)*10;
      this.prevMinValue = this.from;
      this.prevMaxValue = this.to;
    }
  }

  initializeInputListener() {
    const minInputStreams = this.eventsToTrack.map((ev) =>
      fromEvent(this.min.nativeElement, ev)
    );
    const maxInputStreams = this.eventsToTrack.map((ev) =>
      fromEvent(this.max.nativeElement, ev)
    );
    const allStreams = [...minInputStreams, ...maxInputStreams];
    const allEvents$ = merge(...allStreams);
    this.allEvents$ = allEvents$
      .pipe(
        debounce(() => interval(1000)),
        map((event: any) => {
          if (event.target) {
            return +event.target.value;
          }
        }),
        filter((value, index) => {
          return value !== this.prevMinValue && value !== this.prevMaxValue;
        })
      )
      .subscribe((value) => {
        this.prevMinValue = this.from;
        this.prevMaxValue = this.to;
        this.valueChangedInput();
      });
  }

  valueChangedInput() {
    if (
      this.minValue >= this.floor &&
      this.minValue <= this.ceil &&
      this.maxValue >= this.floor &&
      this.maxValue <= this.ceil
    ) {
      this.from = this.minValue;
      this.to = this.maxValue;
      const e = {
        minValue: this.minValue,
        maxValue: this.maxValue,
      };
      this.valueChange.emit(e);
    }
  }

  updateSliderMinValue() {
    // setTimeout(() => {
      if (this.minValue >= this.floor && this.minValue < this.ceil) {
        // const round  = Math.ceil(Math.floor(this.minValue)/10)*10;
        this.from = +(JSON.parse(JSON.stringify(this.minValue))); 
      }
    // }, 100);
  
  }

  updateSliderMaxValue() { 
    // setTimeout(() => {
      if (this.maxValue <= this.ceil && this.maxValue > this.floor) {
      // const round  = Math.ceil(Math.floor(this.maxValue)/10)*10;

        this.to =  +(JSON.parse(JSON.stringify(this.maxValue))); 
      }
    // }, 0);

  }

  updateInputMinValue() {
 
    if (this.from >= this.floor && this.from <= this.ceil) {
      const round  = Math.ceil(Math.floor(this.from)/10)*10;

      this.minValue = +(JSON.parse(JSON.stringify(round)));
    }
  }

  updateInputMaxValue() { 
    if (this.to >= this.floor && this.to <= this.ceil) {
      const round  = Math.ceil(Math.floor(this.to)/10)*10;

      this.maxValue = +(JSON.parse(JSON.stringify(round)));
    }
  }

  emitValues(){
    // const roundMin  = Math.ceil(Math.floor(this.minValue)/10)*10;
    // const roundMax  = Math.ceil(Math.floor(this.maxValue)/10)*10;

    const e = {
      minValue: this.minValue,
      maxValue: this.maxValue,
    };
    this.goEmit.emit(e);
  }

  ngOnDestroy(): void {
    this.allEvents$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.initializeInputs(changes.filter.currentValue);
    }
  }
}
