import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  IFilterData,
  IProductPayload,
  IProductsPayload,
  ISortType,
} from "./../../../shared/models";
import { MatDialog } from "@angular/material/dialog";
import {
  ApiService,
  CacheService,
  EventEmitterService,
  MatDialogUtilsService,
  SeoService,
  UtilsService,
} from "./../../../shared/services";
import { SCROLL_ICON_SHOW_DURATION } from "./../../../shared/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { first } from "rxjs/operators";
import MetaData from "../../../shared/services/seo/meta-data-model";
import { HttpParams } from "@angular/common/http";
import { ICollectionData } from "../../../shared/models/product-collection.interface";
import { CookieService } from "ngx-cookie-service";
import { EnvService } from "../../../shared/services/env.service";
import { Options } from "ng5-slider";
import { isArray } from "util";
import { QuizDialogComponent } from "../quiz-dialog/quiz-dialog.component";
import { NoopScrollStrategy } from "@angular/cdk/overlay";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.less"],
})
export class ProductsComponent implements OnInit {
  productsSubscription: Subscription;
  routeSubscription: Subscription;
  collectionProducts: ICollectionData[] = [];
  futureCollectionProducts: ICollectionData[] = [];
  products: IProductPayload[] = [];
  productFilters: IFilterData = {
    brand: [],
    type: [],
    color: [],
    category: [],
    shape: [],
    seating: [],
    subcategory:[],
    price: { from: 0, min: 0, max: 0, to: 0 },
  };
  department: string;
  category: string;
  categoryTitle: string;
  emailTitle: string;
  totalCount = 0;
  filters = "";
  sortType = "";
  sortTypeList: ISortType[];
  pageNo = 0;
  topPosToStartShowing = 300;
  fixFilterBar = 150;
  isIconShow = false;
  showBar = false;
  isProductFetching = false;
  spinner = "assets/image/spinner.gif";
  showMobileFilter = false;
  showMobileSort = false;
  productsInRow = 2;
  modalSku = "";
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  bpSubscription: Subscription;
  isHandset: boolean;
  scrollToProductSKU = "";
  eventSubscription: Subscription;
  invalidLinkImageSrc = "assets/image/invalid_link.png";
  invalidLink: boolean;
  brandData: any = {
    cover_image: "",
    description: "",
    logo: "",
    name: "",
    url: "",
    value: "",
  };
  // Collection List
  collectionList: any;
  showFilters = false;
  timeout: any;

  seoMetaData: MetaData;
  terminateLoop: boolean;
  user: any;
  pageTitle: any = "";
  color: string;
  shape: string;
  filterData: any;
  sliderOptions: Options = {
    step: 1,
  };
  priceFilter = {};
  widthFilter = {};
  heightFilter = {};
  lengthFilter = {};
  depthFilter = {};
  diameterFilter = {};

  diameter_from: any;
  diameter_to: any;
  width_from: any;
  width_to: any;
  height_from: any;
  height_to: any;
  length_from: any;
  length_to: any;
  depth_from: any;
  depth_to: any;
  price_from: any;
  price_to: any;
  originalFilterData: any;
  showBottomPanel = true;
  filteredElements: string[] = [];
  defaultFilters = ["color", "shape", "style"];
  style: string = "";
  item: any;
  height: number;
  editTitle = false;
  filterKeys: any;
  orderedFilterData: any[] = [];
  sizeBackup: any;
  categorySubscription: Subscription;
  categories: any[] = [];
  department_long: any;
  currentCategory: any;
  subCategory: any;
  iscurrentFilterApplied: boolean;
  width: number;
  urlFilter: any;
  showLoader: boolean;
  isSideNavOpened: boolean;
  constructor(
    public dialog: MatDialog,
    private productElement: ElementRef,
    private apiService: ApiService,
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private matDialogUtils: MatDialogUtilsService,
    public cacheService: CacheService,
    private eventEmitterService: EventEmitterService,
    private seoService: SeoService,
    private utils: UtilsService,
    private cookie: CookieService,
    public env: EnvService
  ) {
    document.addEventListener("click", this.findTopmostParent.bind(this));
  }
  saveTitle() {
    let obj = {
      quiz_id: this.category,
      journey_name: this.pageTitle,
    };
    this.apiService.savePageTitle(obj).subscribe((res) => {
      this.editTitle = false;
    });
  }

  @HostListener("window:wheel", ["$event"])
  onWindowScroll() {
    const fixedSection = document.querySelector(
      ".fixed-prod-head"
    ) as HTMLElement;
    if (fixedSection) {
      const initialOffset = fixedSection.offsetTop;

      if (window.pageYOffset >= 50 || this.cookie.get("popupShow") === "") {
        fixedSection.classList.add("fixed-prod");
      } else {
        fixedSection.classList.remove("fixed-prod");
      }
      if (window.pageYOffset === 0) {
        fixedSection.classList.remove("fixed-prod");
      }
    }
  }

  findTopmostParent(element) {
    let parent = element.target.parentNode;
    let isOk;
    let firstNode = parent.className === "filter-sectionCheck" ? true : false;
    while (parent.parentNode) {
      if (parent.id === "mySidenav") {
        isOk = true;
        break;
      }
      parent = parent.parentNode;
    }
    if (!isOk && !firstNode) {
      this.closeNav();
    }
  }

  ngOnInit(): void {
    // get height  of the page
    this.height = window.innerHeight;
    this.apiService.isSideNavOpened = false;
    this.width = window.innerWidth;
    this.user = JSON.parse(localStorage.getItem("user"));

    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        if (!this.department && !this.category) {
          this.getParams();
          this.getParamsFromQuery();
          this.modalSku =
            this.activeRoute.snapshot.queryParamMap.get("modal_sku");
          this.bpSubscription = this.bpObserver.subscribe(
            (handset: BreakpointState) => {
              this.isHandset = handset.matches;
            }
          );
          this.modalSku =
            this.activeRoute.snapshot.queryParamMap.get("modal_sku");
          this.department = this.activeRoute.snapshot.params.department;
          this.category = this.activeRoute.snapshot.params.category;
          this.subCategory = this.activeRoute.snapshot.params.subCategory;
          this.getCategories();
          this.utils.showCatNav = this.department === "custom" ? false : true;
          if (this.department === "custom") {
            this.apiService
              .getProductPageTitle(this.category)
              .subscribe((res: any[]) => {
                if (res && res.length > 0) {
                  this.pageTitle = res[0].journey_name; //'MY BOARD NAME'// res//
                }
              });
            // this.getFiltersData();
            this.getFilterData();
          } else {
            this.getFilterData();
          }
          this.checkPage();
          // this.routeSubscription = this.activeRoute.params.subscribe(
          //     (routeParams) => {

          //     }
          // );

          if (this.modalSku) {
            const dialogRef = this.matDialogUtils.openMatDialog(this.modalSku);
            dialogRef
              .afterClosed()
              .pipe(first())
              .subscribe((result) => {
                this.seoService.setMetaTags(this.seoMetaData);
              });
          }
        }
      });
    this.apiService
      .getCollections()
      .pipe(first())
      .subscribe((collection) => {
        this.collectionList = collection;
      });
  }

  @HostListener("window:resize")
  onResize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  getCategories() {
    this.categorySubscription = this.apiService
      .getCategories(this.category)
      .subscribe((payload: any) => {
        if(payload){
        this.department_long = payload.department_long;
        this.categories = payload.categories;
        if (this.subCategory) {
          this.currentCategory = this.categories.find(
            (f) => f.link.split("/")[3] === this.subCategory
          ).category;
        }
        this.seoService.setMetaTags(payload);
      }
      });
  }

  setAll() {
    this.location.replaceState(
      "/products/" + this.department + "/" + this.category
    );
    this.currentCategory = "";
    this.subCategory = "";
    this.getFilterData();
    this.loadProducts();
  }
  get getDeptName() {
    return this.department_long.includes("All")
      ? this.department_long.split("All")[1]
      : this.department_long;
  }

  setCategory(c) {
    this.location.replaceState(
      "/products/" +
        this.department +
        "/" +
        this.category +
        "/" +
        c.link.split("/")[3]
    );
    this.currentCategory = c.category;
    this.subCategory = c.link.split("/")[3];
    // this.department = this.category;
    // this.category = c.link.split('/')[3];
    this.getFilterData();
    this.loadProducts();
  }
  setCategoryMobile(c) {
    if (c.category === this.currentCategory) {
      this.location.replaceState(
        "/products/" + this.department + "/" + this.category
      );
      this.currentCategory = "";
      this.subCategory = "";
    } else {
      this.location.replaceState(
        "/products/" +
          this.department +
          "/" +
          this.category +
          "/" +
          c.link.split("/")[3]
      );
      this.currentCategory = c.category;
      this.subCategory = c.link.split("/")[3];
    }
    this.getFilterData();
    this.loadProducts();
  }

  getFilterData(onlyClass = false) {
    let isSort = this.department === "custom" ? false : true;
    if (this.department === "custom") {
      this.apiService
        .getFilterDataCustom(this.category)
        .subscribe((payload: any) => {
          if (payload.seo_data) {
            this.seoMetaData = {
              title: `Shop ${payload.seo_data.dept_name_long} ${payload.seo_data.page_title} at ${this.env.appName}`,
              image: payload.seo_data.cat_image,
              description: payload.seo_data.description,
            };
            if (!this.modalSku) {
              this.seoService.setCanonicalURL();
              this.seoService.setMetaTags(this.seoMetaData);
            }

            this.categoryTitle = payload.seo_data.page_title;
            this.emailTitle = payload.seo_data.email_title;
          }
          delete payload.filterData.category;
          this.productFilters = payload.filterData;

          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();
        });
    } else {
      let dept = this.department;
      let category = this.category;
      if (this.subCategory) {
        // dept = this.category;
        // category = this.subCategory;
        let splitString = this.location.path().toString().split("/products")[1];
        let x; // Splitting at the first occurrence of "/products"
        if (splitString) {
          x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
         
        }
        dept = x.substring(1);
        category = "";
      } else if (dept && category) {
          // dept = this.category;
        // category = this.subCategory;
        let splitString = this.location.path().toString().split("/products")[1];
        let x; // Splitting at the first occurrence of "/products"
        if (splitString) {
          x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
         
        }
        dept = x.substring(1);
        category = "";
      } else {
        dept = "";
      }
      // let finalVal = dept + "/" + category;
      let finalVal = dept ;
      let filter = "";
      
      if (onlyClass) {
        filter =
          "class:" +
          this.productFilters["category"]
            .filter((f) => f.checked)
            .map((f) => f.value)
            .join(",") +
          ";";
        if (filter === "class:;") {
          filter = "";
        }
      } else {
        filter = this.filters;
      }
      this.showLoader = true;
      this.apiService
        .getFilteredData(finalVal, this.filters, this.sortType)
        .subscribe((payload: any) => {
          if (payload.seo_data) {
            this.seoMetaData = {
              title: `Shop ${payload.seo_data.dept_name_long} ${payload.seo_data.page_title} at ${this.env.appName}`,
              image: payload.seo_data.cat_image,
              description: payload.seo_data.description,
            };
            if (!this.modalSku) {
              this.seoService.setCanonicalURL();
              this.seoService.setMetaTags(this.seoMetaData);
            }

            this.categoryTitle = payload.seo_data.page_title;
            this.emailTitle = payload.seo_data.email_title;
          }
          delete payload.filterData.category;
          this.productFilters = payload.filterData;

          this.filterKeys = payload.filterKeys;
          this.sortTypeList = payload.sortType;
          if (payload.total) {
            this.totalCount = payload.total;
          }
          this.getFiltersData();
          this.showLoader = false;
          this.filters = "";
        });
    }
  }
  clearFilters() {
    this.filters = "";
    this.loadProducts();
  }

  onDestroy(): void {
    this.productsSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.bpSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  toggleFilterBar() {
    this.showFilters = !this.showFilters;
  }

  getParams(): void {
    const urlParams: string[] = this.router.url.split("/").slice(1);
    this.department = urlParams[1];
    this.category = urlParams[2].split("?")[1];
  }

  getParamsFromQuery(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.filters = params.filters || "";
      this.urlFilter = JSON.parse(JSON.stringify(this.filters));

      this.pageNo = parseInt(params.pageNo, 10) || 0;
      this.sortType = params.sortType || "recommended";
    });
  }

  checkPage() {
    if (this.category !== "sectionals") {
      if (this.pageNo > 0) {
        this.isProductFetching = true;
        // if(this.department === 'custom'){}
        let isSort = this.department === "custom" ? false : true;
        let dept = this.department;
        let category = this.category;
        if (this.subCategory) {
          // dept = this.category;
          // category = this.subCategory;
          let splitString = this.location.path().toString().split("/products")[1];
          let x; // Splitting at the first occurrence of "/products"
          if (splitString) {
            x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
           
          }
          dept = x.substring(1);
          category = "";
        } else if (dept && category) {
            // dept = this.category;
          // category = this.subCategory;
          let splitString = this.location.path().toString().split("/products")[1];
          let x; // Splitting at the first occurrence of "/products"
          if (splitString) {
            x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
           
          }
          dept = x.substring(1);
          category = "";
        } else {
          dept = "";
        }
        this.productsSubscription = this.apiService
          .getMultiplePageProducts(
            dept,
            category,
            this.filters,
            this.sortType,
            this.pageNo,
            isSort
          )
          .subscribe((response) => {
            let allProducts = [];
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < response.length; i++) {
              allProducts = [...allProducts, ...response[i].products];
            }
            this.products = allProducts;
            this.updateQueryString();
            this.totalCount = response[0].total;
            this.isProductFetching = false;

            if (this.cacheService.data.useCache) {
              this.scrollToProductSKU = this.cacheService.data.productSku;
              this.cacheService.data.useCache = false;
              setTimeout(() => {
                const el = document.getElementById(this.scrollToProductSKU);
                window.scrollTo(0, el.offsetTop - 200);
              }, 500);
            }
          });
      } else {
        this.loadProducts();
      }
    } else {
      this.loadProducts();
    }

    // Code for cached product sku
  }

  getProductwithBrandName(brandValue) {
    console.log("this is brandValue: ", brandValue);
    // let filterValue = '';
    // if (brandValue !== '') {
    //   filterValue = 'brand:' + brandValue + ';';
    // };
  }

  loadProducts(): void {
    this.pageNo = 0;
    if (this.urlFilter) {
      this.filters = this.urlFilter;
    }
    this.isProductFetching = true;
    if (this.category === "sectionals") {
      this.terminateLoop = false;
      this.apiService
        .getCollectionProducts(
          this.department,
          this.category,
          this.filters,
          this.sortType,
          this.pageNo
        )
        .then(
          (payload: IProductsPayload) => {
            this.collectionProducts = payload.collection_data;
            this.collectionProducts.forEach((f) => {
              f.collection_omega &&
                f.collection_omega.forEach((e) => {
                  e.products &&
                    e.products.forEach((a) => {
                      a.is_price =
                        a.min_price == a.max_price
                          ? "" + a.min_price
                          : a.min_price + "-" + a.max_price;
                    });
                });
            });
            this.getNextPageDataSec();
            this.updateQueryString();
            this.isProductFetching = false;
            this.invalidLink = false;
            this.totalCount = payload.total_beta;
          },
          (err) => {
            this.invalidLink = true;
            this.isProductFetching = false;
          }
        );
    } else {
      let isSort = this.department === "custom" ? false : true;
      let dept = this.department;
      let category = this.category;
      if (this.subCategory) {
        // dept = this.category;
        // category = this.subCategory;
        let splitString = this.location.path().toString().split("/products")[1];
        let x; // Splitting at the first occurrence of "/products"
        if (splitString) {
          x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
         
        }
        dept = x.substring(1);
        category = "";
      } else if (dept && category) {
          // dept = this.category;
        // category = this.subCategory;
        let splitString = this.location.path().toString().split("/products")[1];
        let x; // Splitting at the first occurrence of "/products"
        if (splitString) {
          x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
         
        }
        dept = x.substring(1);
        category = "";
      } else {
        dept = "";
      }
      console.log(this.location.path());
      this.productsSubscription = this.apiService
        .getProducts(dept, category, this.filters, this.sortType, 0, isSort)
        .pipe(first())
        .subscribe(
          (payload: IProductsPayload) => {
            this.products = payload.products;

            // delete payload.filterData.category;
            // this.productFilters = payload.filterData;
            // this.sortTypeList = payload.sortType;
            this.totalCount = payload.total;
            this.updateQueryString();
            this.isProductFetching = false;
            this.invalidLink = false;
          },
          (error) => {
            this.invalidLink = true;
            this.isProductFetching = false;
          }
        );
    }
  }
  onScroll() {
    if (this.isProductFetching) {
      return;
    }

    if (this.pageNo == 1) {
      const popUpAlreadyShown = this.cookie.get("popupShow");
      if (!popUpAlreadyShown) {
        this.matDialogUtils.openNewsLetter()
        // setTimeout(() => , 1000);
      }
    }

    if (this.department && this.category) {
      if (this.category === "sectionals") {
        // if(this.pageNo)

        if (!this.terminateLoop) {
          //     this.pageNo += 1;
          //     // this.collectionProducts = [...this.collectionProducts,...this.futureCollectionProducts];
          this.updateQueryString();
          //     this.getNextPageDataSec();
        }
      } else {
        this.pageNo += 1;
        this.isProductFetching = true;
        let isSort = this.department === "custom" ? false : true;
        let dept = this.department;
        let category = this.category;
        if (this.subCategory) {
          // dept = this.category;
          // category = this.subCategory;
          let splitString = this.location.path().toString().split("/products")[1];
          let x; // Splitting at the first occurrence of "/products"
          if (splitString) {
            x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
           
          }
          dept = x.substring(1);
          category = "";
        } else if (dept && category) {
            // dept = this.category;
          // category = this.subCategory;
          let splitString = this.location.path().toString().split("/products")[1];
          let x; // Splitting at the first occurrence of "/products"
          if (splitString) {
            x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
           
          }
          dept = x.substring(1);
          category = "";
        } else {
          dept = "";
        }
        this.productsSubscription = this.apiService
          .getProducts(
            dept,
            category,
            this.filters,
            this.sortType,
            this.pageNo,
            isSort
          )
          .subscribe(
            (payload: IProductsPayload) => {
              this.products = [...this.products, ...payload.products];

              this.updateQueryString();
              this.isProductFetching = false;
            },
            (err) => {
              this.isProductFetching = false;
            }
          );
      }
    }
  }
  async getNextPageDataSec() {
    await this.apiService
      .getCollectionProducts(
        this.department,
        this.category,
        this.filters,
        this.sortType,
        this.pageNo + 1
      )
      .then(
        (payload: IProductsPayload) => {
          if (payload.collection_data) {
            payload.collection_data.forEach((f) => {
              f.collection_omega &&
                f.collection_omega.forEach((e) => {
                  e.products &&
                    e.products.forEach((a) => {
                      a.is_price =
                        a.min_price == a.max_price
                          ? "" + a.min_price
                          : a.min_price + "-" + a.max_price;
                    });
                });
            });
            this.collectionProducts = [
              ...this.collectionProducts,
              ...payload.collection_data,
            ];
            this.totalCount = payload.total_beta;
            if (!this.terminateLoop) {
              this.pageNo += 1;
              this.getNextPageDataSec();
            }
          } else {
            setTimeout(() => {
              this.terminateLoop = true;
            }, 5000);
          }
        },
        (err) => {
          this.terminateLoop = true;
          // this.isProductFetching = false;
        }
      );
  }

  updateQueryString(): void {
    let params = new HttpParams();
    if (this.filters) {
      params = params.set("filters", this.filters);
    }
    let isSort = this.department === "custom" ? false : true;
    if (this.sortType && isSort) {
      params = params.set("sortType", this.sortType);
    }
    if (this.pageNo) {
      params = params.set("pageNo", this.pageNo.toString());
    }
    this.location.replaceState(
      window.location.pathname,
      params.toString(),
      this.location.getState()
    );

    if (this.pageNo == 1) {
      this.openQuizPrompt();
    }

    if ((this.pageNo == 1 || this.pageNo == 2) && !this.isLoggedIn) {
      this.matDialogUtils.openNewsLetter(this.isHandset)
    }
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }

  onSetFilters(e): void {
    this.filters = e;
    if (e.includes("class")) {
      // this.loadProducts();
      this.getFilterData(true);
    }
    this.loadProducts();
    // this.getFilterData();
  }

  onSetBrand(brandName: string) {
    if (brandName === "") {
      this.filters = "";
    } else {
      // const checkBrand = this.filters.indexOf('brand:');
      // if (checkBrand < 0) {
      //     this.filters += 'brand:' + brandName + ';';
      // } else {
      //     const endBrand = this.filters.indexOf(';');
      //     this.filters = 'brand:' + brandName + ';' + this.filters.substr(endBrand + 1);
      // }
      // this.getBrandData(brandName);
    }
    this.loadProducts();
  }

  onSetSortType(e): void {
    this.sortType = e;
    this.loadProducts();
  }

  onSetMobileToggle($e): void {
    this.toggleMobileFilter();
  }

  onSetSortToggle($e): void {
    this.toggleMobileSort();
  }

  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.isIconShow = scrollPosition >= this.topPosToStartShowing;
    this.showBar = scrollPosition >= this.fixFilterBar;
    const self = this;
    if (this.isIconShow) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        self.isIconShow = false;
      }, SCROLL_ICON_SHOW_DURATION);
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  toggleMobileFilter() {
    this.showMobileFilter = !this.showMobileFilter;
    if (this.showMobileFilter) {
      this.hideIntercom();
    } else {
      this.showIntercom();
    }
  }

  hideIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if(intercom && intercom.classList){
    intercom.classList.add("dn");
    }
  }

  showIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    intercom.classList.remove("dn");
  }

  toggleMobileSort() {
    this.showMobileSort = !this.showMobileSort;
  }

  toggleMobileView() {
    if (this.productsInRow === 3) {
      this.productsInRow = 1;
    } else {
      this.productsInRow += 1;
    }
  }
  openQuizPrompt() {
    const popUpAlreadyShown = this.cookie.get("quizPopupShow");

        if(!popUpAlreadyShown && (this.router.url.includes('/products/living/sofa')) ){
        this.dialog.open(QuizDialogComponent, {
             hasBackdrop: true,
            disableClose: true,
            data: {
                handset:this.isHandset
            },
            scrollStrategy: new NoopScrollStrategy()
        })
    }
  }

  assignSliderData(event, name) {
    if (name == "diameter") {
      this.diameter_from = event.minValue;
      this.diameter_to = event.maxValue;
    } else if (name == "width") {
      this.width_from = event.minValue;
      this.width_to = event.maxValue;
    } else if (name == "height") {
      this.height_from = event.minValue;
      this.height_to = event.maxValue;
    } else if (name == "length") {
      this.length_from = event.minValue;
      this.length_to = event.maxValue;
    } else if (name == "depth") {
      this.depth_from = event.minValue;
      this.depth_to = event.maxValue;
    } else if (name == "price") {
      this.price_from = event.minValue;
      this.price_to = event.maxValue;
    }
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }
  }

  resetFilter() {
    this.iscurrentFilterApplied = false;

    this.filterData = JSON.parse(JSON.stringify(this.originalFilterData));

    this.orderedFilterData.forEach((f) => {
      if (f.value && isArray(f.value) && f.value.length > 0) {
        f.value.forEach((element) => {
          if (element.checked) {
            element.checked = false;
          }
        });
      }
    });

    let szData = this.originalFilterData.find((f) => f.key === "size");

    if (szData) {
      this.sizeBackup = JSON.parse(JSON.stringify(szData.value));
      this.widthFilter = this.sizeBackup["width"];
      this.heightFilter = this.sizeBackup["height"];
      this.lengthFilter = this.sizeBackup["length"];
      this.depthFilter = this.sizeBackup["depth"];
    }
    // this.filterData["size"] = [{ name: "size" }];
    this.priceFilter = this.productFilters["price"];
    this.priceFilter["name"] = "price";
    // this.diameterFilter = this.filterData["diameter"][0];
    // this.diameterFilter["name"] = "diameter";
    this.diameter_from = null;
    this.diameter_to = null;
    this.width_from = null;
    this.width_to = null;
    this.height_from = null;
    this.height_to = null;
    this.length_from = null;
    this.length_to = null;
    this.depth_from = null;
    this.depth_to = null;
    this.price_from = null;
    this.price_to = null;
    this.filteredElements = [];
    let dept = this.department;
    let category = this.category;
    if (this.subCategory) {
      // dept = this.category;
      // category = this.subCategory;
      let splitString = this.location.path().toString().split("/products")[1];
      let x; // Splitting at the first occurrence of "/products"
      if (splitString) {
        x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
       
      }
      dept = x.substring(1);
      category = "";
    } else if (dept && category) {
        // dept = this.category;
      // category = this.subCategory;
      let splitString = this.location.path().toString().split("/products")[1];
      let x; // Splitting at the first occurrence of "/products"
      if (splitString) {
        x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
       
      }
      dept = x.substring(1);
      category = "";
    } else {
      dept = "";
    }
    this.productsSubscription = this.apiService
      .getProducts(dept, category, "", this.sortType, 0, false)
      .pipe(first())
      .subscribe(
        (payload: IProductsPayload) => {
          this.products = payload.products;

          // delete payload.filterData.category;
          // this.productFilters = payload.filterData;
          // this.sortTypeList = payload.sortType;

          this.totalCount = payload.total;
          this.updateQueryString();
          this.isProductFetching = false;
          this.invalidLink = false;
          this.closeNav();
        },
        (error) => {
          this.invalidLink = true;
          this.isProductFetching = false;
        }
      );
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "650px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    this.apiService.isSideNavOpened = true;
  }

  closeNav() {
    if( document.getElementById("mySidenav")){
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.backgroundColor = "white";
    this.apiService.isSideNavOpened = false;
    }
  }
  formFilterURL(): string {
    let filterUrl = "";

    if (this.productFilters) {
      this.filteredElements.forEach((f) => {
        if (
          f != "price" &&
          f != "diameter" &&
          f != "width" &&
          f != "height" &&
          f != "length" &&
          f != "depth" &&
          f != "category"
        ) {
          let checked = [];
          this.productFilters[f].forEach((element) => {
            if (element.checked) {
              checked.push(element.value);
            }
          });
          filterUrl = filterUrl + `${f}:${checked.join(",")};`;
        } else if (f == "category") {
          let catchecked = [];
          if (this.productFilters[f]) {
            this.productFilters[f].forEach((element) => {
              if (element.checked) {
                catchecked.push(element.value);
              }
            });
            filterUrl = filterUrl + `${"class"}:${catchecked.join(",")};`;
          }
        } else if (f == "price") {
          filterUrl =
            filterUrl +
            `${"price_from"}:${this.price_from};${"price_to"}:${
              this.price_to
            };`;
        } else if (f == "diameter") {
          filterUrl =
            filterUrl +
            `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${
              this.diameter_to
            };`;
        } else if (f == "width") {
          filterUrl =
            filterUrl +
            `${"width_from"}:${this.width_from};${"width_to"}:${
              this.width_to
            };`;
        } else if (f == "height") {
          filterUrl =
            filterUrl +
            `${"height_from"}:${this.height_from};${"height_to"}:${
              this.height_to
            };`;
        } else if (f == "length") {
          filterUrl =
            filterUrl +
            `${"length_from"}:${this.length_from};${"length_to"}:${
              this.length_to
            };`;
        } else if (f == "depth") {
          filterUrl =
            filterUrl +
            `${"depth_from"}:${this.depth_from};${"depth_to"}:${
              this.depth_to
            };`;
        }
      });
    }
    return filterUrl;
  }
  applyFilter(filterFromMobile?) {
    let filterData = this.formFilterURL();
    let dept = this.department;
    let category = this.category;
    if (this.subCategory) {
      // dept = this.category;
      // category = this.subCategory;
      let splitString = this.location.path().toString().split("/products")[1];
      let x; // Splitting at the first occurrence of "/products"
      if (splitString) {
        x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
       
      }
      dept = x.substring(1);
      category = "";
    } else if (dept && category) {
        // dept = this.category;
      // category = this.subCategory;
      let splitString = this.location.path().toString().split("/products")[1];
      let x; // Splitting at the first occurrence of "/products"
      if (splitString) {
        x = splitString.split("?", 2)[0]; // Splitting the second part at the first occurrence of "?"
       
      }
      dept = x.substring(1);
      category = "";
    } else {
      dept = "";
    }

    this.filters = filterFromMobile ? filterFromMobile : filterData;

    this.iscurrentFilterApplied = false;

    this.productsSubscription = this.apiService
      .getProducts(dept, category, filterData, this.sortType, 0, false)
      .pipe(first())
      .subscribe(
        (payload: IProductsPayload) => {
          this.products = payload.products;

          // delete payload.filterData.category;
          // this.productFilters = payload.filterData;
          // this.sortTypeList = payload.sortType;

          this.totalCount = payload.total;
          this.updateQueryString();
          this.isProductFetching = false;
          this.invalidLink = false;
          this.closeNav();
        },
        (error) => {
          this.invalidLink = true;
          this.isProductFetching = false;
        }
      );
    // this.apiService
    //   .getProduct(data, filterData, this.pageNo)
    //   .subscribe((response: any) => {
    //     // this.productList =  response.products;
    //     // if (this.productList.length === 0) {
    //     //   this.productList = [...response.products];
    //     // } else {
    //     //   this.productList = [...this.productList, ...response.products];
    //     // }
    //   });
  }

  selectFilter(d, name) {
    d.checked = !d.checked;
    if (this.productFilters[name].filter((f) => f.checked).length > 0) {
      if (!this.filteredElements.includes(name)) {
        this.filteredElements.push(name);
      }
    } else {
      this.filteredElements = this.filteredElements.filter((f) => f != name);
    }

    if (name === "category") {
      this.getFilterData(true);
    }

    this.iscurrentFilterApplied = true;
  }
  selectAll(name) {
    this.productFilters[name].forEach((element) => {
      element.checked = true;
    });
    if (!this.filteredElements.includes(name)) {
      this.filteredElements.push(name);
    }

    if (name === "category") {
      this.getFilterData(true);
    }

    this.iscurrentFilterApplied = true;
  }
  unSelectAll(name) {
    this.productFilters[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);

    if (name === "category") {
      this.getFilterData(true);
    }

    this.iscurrentFilterApplied = true;
  }

  getFiltersData() {
    let filterUrl = "";
    if (this.style) {
      filterUrl = filterUrl + `${"style"}:${this.style.split(" ")[0]};`;
    }
    if (this.color) {
      filterUrl = filterUrl + `${"color"}:${this.color};`;
    }
    if (this.shape) {
      filterUrl = filterUrl + `${"shape"}:${this.shape};`;
    }

    const data = {
      class: this.item,
      room: this.style.split(" ")[1],
    };

    delete this.productFilters["diameter"];
    delete this.productFilters["square"];

    // this.productList = res.products;
    // this.pinExpanded = false;
    for (var k in this.productFilters) {
      if (
        this.productFilters[k] &&
        isArray(this.productFilters[k]) &&
        (this.productFilters[k].length == 0 ||
          this.productFilters[k].every((e) => e.enabled == false))
      ) {
        delete this.productFilters[k];
      }
    }
    this.orderedFilterData = [];

    for (let i = 0; i < this.filterKeys.length; i++) {
      if (this.productFilters[this.filterKeys[i]]) {
        if (this.productFilters[this.filterKeys[i]]) {
          this.orderedFilterData.push({
            key: this.filterKeys[i],
            value: this.productFilters[this.filterKeys[i]],
            opened: false
          });
        }
      }
    }

    this.filterData = this.orderedFilterData;
    this.originalFilterData = JSON.parse(JSON.stringify(this.filterData));
    this.sizeBackup = JSON.parse(JSON.stringify(this.productFilters["size"]));
    this.widthFilter = this.sizeBackup["width"];
    this.heightFilter = this.sizeBackup["height"];
    this.lengthFilter = this.sizeBackup["length"];
    this.depthFilter = this.sizeBackup["depth"];
    // this.filterData["size"] = [{ name: "size" }];
    this.priceFilter = this.productFilters["price"];
    this.priceFilter["name"] = "price";
    // this.diameterFilter = this.filterData["diameter"][0];
    // this.diameterFilter["name"] = "diameter";

    this.filteredElements.forEach((f) => {
      if (
        f != "price" &&
        f != "diameter" &&
        f != "width" &&
        f != "height" &&
        f != "length" &&
        f != "depth"
      ) {
        let checked = [];
        this.filterData[f].forEach((element) => {
          if (f === "color") {
            let ind = this.filterData[f].findIndex(
              (f) => f.name.toLowerCase() === this.color.toLowerCase()
            );
            if (ind != -1) {
              this.filterData[f][ind].checked = true;
            }
          }
          if (f === "shape") {
            let ind = this.filterData[f].findIndex(
              (f) => f.name.toLowerCase() === this.shape.toLowerCase()
            );
            if (ind != -1) {
              this.filterData[f][ind].checked = true;
            }
          }
          if (f === "style") {
            let ind = this.filterData[f].findIndex(
              (f) =>
                f.name.toLowerCase() === this.style.split(" ")[0].toLowerCase()
            );
            if (ind != -1) {
              this.filterData[f][ind].checked = true;
            }
          }
          if (element.checked) {
            checked.push(element.name);
          }
        });
        filterUrl = filterUrl + `${f}:${checked.join(",")};`;
      } else if (f == "price") {
        filterUrl =
          filterUrl +
          `${"price_from"}:${this.price_from};${"price_to"}:${this.price_to};`;
      } else if (f == "diameter") {
        filterUrl =
          filterUrl +
          `${"diameter_from"}:${this.diameter_from};${"diameter_to"}:${
            this.diameter_to
          };`;
      } else if (f == "width") {
        filterUrl =
          filterUrl +
          `${"width_from"}:${this.width_from};${"width_to"}:${this.width_to};`;
      } else if (f == "height") {
        filterUrl =
          filterUrl +
          `${"height_from"}:${this.height_from};${"height_to"}:${
            this.height_to
          };`;
      } else if (f == "length") {
        filterUrl =
          filterUrl +
          `${"length_from"}:${this.length_from};${"length_to"}:${
            this.length_to
          };`;
      } else if (f == "depth") {
        filterUrl =
          filterUrl +
          `${"depth_from"}:${this.depth_from};${"depth_to"}:${this.depth_to};`;
      }
    });
  }

  getCheckedLength(name): boolean {
    return this.filterData[name].filter((f) => f.checked).length > 0;
  }
  getSelections(name): string {
    return this.filterData[name]
      .filter((f) => f.checked)
      .map((f) => f.name)
      .join(",");
  }
  clearSelections(name) {
    this.filterData[name].forEach((element) => {
      element.checked = false;
    });
    this.filteredElements = this.filteredElements.filter((f) => f != name);
    this.applyFilter();
  }
  collapSection(o:any){
    o.opened = !o.opened;
  }
  showClear(key){
    if(this.filterData[key] && this.filterData[key].length > 0){
      return this.filterData[key].some((f) => f.checked)
    } else {
      return false;
    }
  }
}
