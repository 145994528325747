import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddToOrderComponent } from 'src/app/modules/order-list/add-to-order/add-to-order.component';
import { ApiService } from 'src/app/shared/services';
import { AddShoppingProductDialogComponent } from '../add-shopping-product-dialog/add-shopping-product-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.less']
})
export class ShoppingListComponent implements OnInit {
  @Input() isHandset: boolean;
  productListings: any = null;
  isFetched: boolean;
  qtyList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  total=0;
  spinner = "assets/image/spinner.gif";
  public Math:Math = Math;
  @ViewChild("confirmDialog") confirmDialog: TemplateRef<any>;
  prodToRemove: any;

  constructor(private api: ApiService,private snackbar:MatSnackBar,
    public dialog:MatDialog, private router:Router
  ) { }

  ngOnInit(): void {
    console.log(this.api.projectId)
    this.getShoppingList();
  }

  getShoppingList() {
    this.isFetched = false;
    // this.api.projectId = '170874'
    this.productListings= null;
    this.api.getShoppingList(this.api.projectId).subscribe((res: any) => {

      if (res && res.products) {
        this.productListings = res.products;
        this.productListings.forEach(f => {
          f['checked'] = false;
          f['qty'] = JSON.parse(JSON.stringify(f.count)) ;

        });
        this.total = this.productListings.reduce((a,b)=>{
          return a+b.total_price;
        },0)
        this.isFetched = true;
      }
    })
  }

  openConfirmPopup(product){
    this.prodToRemove = product;
    this.dialog.open(this.confirmDialog,{
      width:'300',

    })
  }

  remove(){
    let productsToRemove =[];
 
     productsToRemove.push( {product_sku:this.prodToRemove.product_sku,count:this.prodToRemove.count})
 
    this.decreaseQuantity(productsToRemove,true)
  }

  create(){
    this.router.navigateByUrl(`/admin/dashboard/orders/new-order?room_id=${this.api.projectId}`);

  }

  get selections():boolean{
    return this.productListings?.some(f=>f.checked);
  }


  onQuantityChanged(product, count, quantity) {
    if ((+quantity) >= count) {
      const updateQuantity = (+quantity) - count;
      this.increaseQuantity(product, updateQuantity); 
    } else {
      const updateQuantity = count - (+quantity) ;

      let productsToRemove = [{product_sku:product.product_sku, count: updateQuantity}];
      this.decreaseQuantity(productsToRemove,false);
    }
    setTimeout(() => {
      
      product.total_price = parseFloat( quantity) * parseFloat(product.price) ;
    }, 500);
  }

  openAddToOrder() {
    this.dialog
      .open(AddShoppingProductDialogComponent, {
        // width: "700px",
        data: this.api.projectId,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getShoppingList();
        }
      });
  }


  increaseQuantity(product, quantity) {
    const postData = {
      product_sku: product.product_sku,
      count: +quantity,
      parent_sku: product.parent_sku,
      isServiceRepOrder: 1,
      room_id: this.api.projectId
      // cart_id: this.api.projectId,


    };
    this.api.addCartProduct(postData).subscribe(
      (payload: any) => { 
        this.snackbar.open('Quantity updated','x',{duration:3000})
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  decreaseQuantity(productsToRemove, isCompleteRemove) {
    let data = {isServiceRepOrder:1,
      "products":productsToRemove, 
      room_id: this.api.projectId

    // cart_id: this.api.projectId, 

    };
    this.api.removeCartProduct(data).subscribe(
      (payload: any) => { 
        if(isCompleteRemove){
        this.snackbar.open('Produce removed successfully','x',{duration:3000});
        this.dialog.closeAll();
        this.getShoppingList();

        } else {
        this.snackbar.open('Quantity updated','x',{duration:3000});

        }

      },
      (error: any) => {
        console.log(error);
      }
    );
  }
 
  openProductInNewTab(p: any) {
    this.router.navigate([]).then(() => {
      const productUrl = p.parent_sku ? p.parent_sku : p.product_sku;
      window.open(`product/${productUrl}`, "_blank");
    });
  }

}
