import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
} from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-projects-landing",
  templateUrl: "./projects-landing.component.html",
  styleUrls: ["./projects-landing.component.less"],
})
export class ProjectsLandingComponent implements OnInit, OnDestroy {
  projectList: any[] = [];
  userName: string;
  designStatus: any[] = [];
  roomCodes: any[] = [];
  eventSubscription: Subscription;
  hasProjects = "";
  outlineImg: string;

  constructor(
    private api: ApiService,
    public envService: EnvService,
    private eventEmitterService: EventEmitterService,
    private matDialogUtil: MatDialogUtilsService
  ) {
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        //   this.customerData.email = user.email;
        // if(user.email){ 
        this.initCalls();
        // }
      });
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }
  initCalls() {
    if (this.isLoggedIn) {
      this.api.getDesignStausList().subscribe((res: any) => {
        console.log(res);
        this.designStatus = res;
        this.api.getRoomCodes().subscribe((res: any) => {
          this.roomCodes = res;
          this.get();
        });
      });
    } else {
      this.matDialogUtil.openSignupDialog(
        window.innerWidth >= 600 ? false : true
      );
    }
  }
  ngOnInit(): void {
    this.outlineImg =  'https://s3.us-east-2.amazonaws.com/lazysuzy-images/site/design/design-project-outline.jpg'
  }
titleCase(input):string{
 return input
  .split(" ")
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(" ");
}
  get() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.userName = user.name; // user.first_name + ' ' + user.last_name;
    // this.userName = t
    this.api.getAllAppointments("", "", user.id).subscribe((res) => {
      if (res && res.length > 0) {
        this.projectList = res; 
          this.hasProjects = "YES";
        
        this.projectList.forEach((f) => {

          let path =this.designStatus.find(
            (fs) => fs.code === f.status
          )?.redirect_path;

          f["btnAction"] = this.designStatus.find(
            (fs) => fs.code === f.status
          ).label_action;
          const labelData = this.roomCodes.find((fs) => fs.code === f.room)
          f["label"] = labelData?labelData.label: this.titleCase(f.room);
          f["path"] = path? path.replace('%',f.project_id):'' ;
          const inspoIndex = f.intakeimage.findIndex(f=>f.type==='inspo');
          const roomInddex = f.intakeimage.findIndex(f=>f.type==='current');
          if(f.status==='02'){
          f["image"] = (f.intakeimage.length>0 &&  inspoIndex>-1 && f.intakeimage[0].images.length>0 &&
             f.intakeimage[0].images[0].image )? 
          f.intakeimage[0].images[0].image:
           this.outlineImg;
          } else   if(f.status==='03'){
            f["image"] = (f.intakeimage.length>0 &&  roomInddex>-1 && f.intakeimage[0].images.length>0 &&
               f.intakeimage[0].images[0].image )? 
            f.intakeimage[0].images[0].image:
             this.outlineImg;
            } else {
              f["image"] =  this.outlineImg
            }
            // this.envService.assetBaseUrl.substring(
            //   0,
            //   this.envService.assetBaseUrl.length - 1
            // ) + this.roomCodes.find((fs) => fs.code === f.room)?.image;
        });
        console.log(this.projectList, "PL");
      } else {
        this.hasProjects = "NO";

      }
    },err=>{
      this.hasProjects = "NO";
    });
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }
}
