import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AllProductsComponent,
    CartComponent,
    CategoriesComponent,
    ForgotPasswordComponent,
    LandingComponent,
    ProductsComponent,
    ResetPasswordComponent,
    SearchComponent,
    WishlistComponent,
} from './feature/containers';
import { AboutusComponent, PrivacyPolicyComponent, TermsOfServiceComponent } from './core/components';
import {
    EditMyProfileComponent,
    OrderSuccessComponent,
    PaymentComponent,
    PublicProfileComponent,
    ReviewFormMobileComponent
} from './feature/components';
import { environment } from 'src/environments/environment';
import { ProductCollectionsComponent } from './feature/containers/product-collections/product-collections.component';
import { AllReviewsMobileComponent } from './feature/components/product-details-mobile/all-reviews-mobile/all-reviews-mobile.component';
import { SellerGuard } from './shared/services/seller/seller.guard';
import { AdminGuard } from './shared/services/admin/admin.guard';
import { QuizDesktopComponent } from './quiz/quiz-desktop/quiz-desktop.component';
import { ResultPageComponent } from './quiz/result-page/result-page.component';
import { AdminDashboardComponent } from './feature/components/admin-dashboard/admin-dashboard.component';
import { ProductKeywordMappingComponent } from './feature/containers/product-keyword-mapping/product-keyword-mapping.component';
import { MailListingComponent } from './feature/containers/mail-listing/mail-listing.component';
import { NoProductFoundComponent } from './shared/components/no-product-found/no-product-found.component';

import { ShopComponent } from './feature/containers/landing/shop/shop.component';
import { ProductPreviewComponent } from './feature/components/product-preview/product-preview.component';
import { OnboardQuizDesktopComponent } from './quiz/onboard-quiz-desktop/onboard-quiz-desktop.component';
import { DesignDashboardComponent } from './feature/containers/design-dashboard/design-dashboard.component';

import { ProductAllComponent } from './feature/components/product-all/product-all.component';

import { BrandsComponent } from './feature/components/brands/brands.component';
import { CbInventoryComponent } from './feature/containers/cb-inventory/cb-inventory.component';
import { BookingPaypalComponent } from './core/components/booking-paypal/booking-paypal.component';
import { ProjectDetailsComponent } from './quiz/project-details/project-details.component';
import { DesignSignUpComponent } from './quiz/design-sign-up/design-sign-up.component';
import { ThankYouComponent } from './feature/components/thank-you/thank-you.component';
import { ViewProjectIntakeFormComponent } from './feature/components/view-project-intake-form/view-project-intake-form.component';
import { ProductRestrictedComponent } from './feature/components/product-restricted/product-restricted.component';
import { ProjectsLandingComponent } from './feature/components/projects-landing/projects-landing.component';
import { DesignProposalsComponent } from './feature/components/design-proposals/design-proposals.component';

const routes: Routes = [
    { path: '', component: LandingComponent, pathMatch: 'full' },
    { path: 'shop', component: ShopComponent },
    {
        path: environment.BOARD_BASE_HREF,
        loadChildren: () =>
            import('./modules/board/board.module').then((m) => m.BoardModule),
        // pathMatch: 'full'
    },

    { path: 'search', component: SearchComponent },
    {
        path: 'chat',
        loadChildren: () =>
            import('./chat/chat.module').then((m) => m.ChatModule),
    },
    {
        path: 'c/:id',
        loadChildren: () =>
            import('./chat/chat.module').then((m) => m.ChatModule),
    },
    {
        path: 'c',
        loadChildren: () =>
            import('./chat/chat.module').then((m) => m.ChatModule),
    },
    { path: 'aboutus', component: AboutusComponent },
    { path: 'termsofservice', component: TermsOfServiceComponent },
    { path: 'privacypolicy', component: PrivacyPolicyComponent },
    { path: 'cart', component: CartComponent },

    { path: 'design/book/payment/:bookingId', component: BookingPaypalComponent },
    { path: 'design/book/sign-up', component: DesignSignUpComponent },
    { path: 'design/projects', component: ProjectsLandingComponent },
    { path: 'design/projects/:id/view-project-intake', component: ViewProjectIntakeFormComponent },
    { path: 'design/projects/:id/view-client-files', component: ViewProjectIntakeFormComponent },
    { path: 'design/projects/:id/design-proposals', component: ViewProjectIntakeFormComponent },
    { path: 'design/projects/:id/shopping-list', component: ViewProjectIntakeFormComponent },


    { path: 'design/book/project-details/:bookingId', component: ProjectDetailsComponent },
 
    { path: 'design/projects/:id/landing', component: ThankYouComponent },  
    { path: 'design/projects/:id/designs', loadChildren: ()=>
        import('./modules/project-designs/project-designs.module').then((m) => m.ProjectDesignsModule),

     },  
     { path: 'design/projects/:id/cart', loadChildren: ()=>
        import('./modules/project-designs/project-designs.module').then((m) => m.ProjectDesignsModule),

     },  


    // { path: 'brands', component: BrandsComponent },
    {
        path: 'design',
        loadChildren: () =>
            import('./design/design.module').then((m) => m.DesignModule),
    },
    {
        path: 'room-profile/:projectId',
        loadChildren: () =>
            import('./room-profile/room-profile.module').then((m) => m.RoomProfileModule),
    },
    { path: 'checkout', component: PaymentComponent },
    { path: 'order/:order', component: OrderSuccessComponent },
    { path: 'wishlist', component: WishlistComponent, pathMatch: 'full' },
    // {
    //     path: 'products/all',
    //     component: AllProductsComponent,
    //     pathMatch: 'full',
    // },
    // {
    //     path: 'brand/:brandName',
    //     component: AllProductsComponent,
        
    // },
    {
        path: 'products/collections',
        component: ProductCollectionsComponent,
        pathMatch: 'full',
    },
    // {
    //     path: 'product/contact',
    //     component: NoProductFoundComponent
    // },
    {
        path: 'product/:id/preview',
        component: ProductPreviewComponent
    },
    {
        path: 'product/:id/r',
        component: ProductRestrictedComponent
    },
    {
        path: 'products/new',
        component: ProductKeywordMappingComponent,
        pathMatch: 'full',
    },
    {
        path: 'products/sale',
        component: ProductKeywordMappingComponent,
        pathMatch: 'full',
    },
    {
        path: 'sale',
        component: ProductKeywordMappingComponent,
        pathMatch: 'full',
    },
    {
        path: 'products/:keyword',
        component: ProductKeywordMappingComponent,
        pathMatch: 'full',
    },
 
    {
        path: 'products/:department',
        component: CategoriesComponent,
        pathMatch: 'full',
    },
    {
        path: 'shop/products/:department/:category',
        component: CategoriesComponent,
        pathMatch: 'full',
    },
    {
        path: 'products/:department/:category',
        component: ProductsComponent,
        pathMatch: 'full',
    }, {
        path: 'products/:department/:category/:subCategory',
        component: ProductsComponent,
        pathMatch: 'full',
    },
    {
        path: 'product/:product',
        loadChildren: () =>
            import(
                './feature/containers/product-details/product-details.module'
            ).then((m) => m.ProductDetailsModule),
        pathMatch: 'full',
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        pathMatch: 'full',
    },
    {
        path: 'faq',
        loadChildren: () =>
            import('./feature/faq/faq.module').then((m) => m.FaqModule),
    },
    {
        path: 'furniture-care',
        loadChildren: () =>
            import('./feature/furniture-care/furniture-care.module').then(
                (m) => m.FurnitureCareModule
            ),
    },
    {
        path: 'design/designer/products/all',
        component: ProductAllComponent,
      },
    {
        path: 'design/designer',
        loadChildren: () =>
            import('./modules/seller/seller.module').then((m) => m.SellerModule),
        canActivate: [SellerGuard],
    }, 
    {
        path: 'admin/dashboard',
        component: AdminDashboardComponent,
        canActivate: [AdminGuard],

    },
    {
        path: 'admin/dashboard/mailing-list',
        component: MailListingComponent

    },
    {
        path: 'admin/dashboard/cb-inventory',
        component: CbInventoryComponent

    },
    {
        path: 'admin/dashboard/design/bookings',
        component: DesignDashboardComponent,
        canActivate: [AdminGuard],

    },
    {
        path: 'admin/dashboard/reporting',
        loadChildren: () =>
            import(
                './modules/reporting/reporting.module'
            ).then((m) => m.ReportingModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'admin/dashboard/new-products',
        loadChildren: () =>
            import(
                './modules/new-products-dashboard/new-products-dashboard.module'
            ).then((m) => m.NewProductsDashboardModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'admin/dashboard/edit-products',
        loadChildren: () =>
            import(
                './modules/new-products-dashboard/edit-products-dashboard.module'
            ).then((m) => m.EditProductsDashboardModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'admin/dashboard/orders',
        loadChildren: () =>
            import(
                './modules/order-list/order-list.module'
            ).then((m) => m.OrderListModule),
        canActivate: [AdminGuard],
    },
    {
        path: 'admin/dashboard/collections',
        loadChildren: () =>
            import(
                './modules/collections-dashboard/collections-dashboard.module'
            ).then((m) => m.CollectionsDashboardModule),
        canActivate: [AdminGuard]
    },
    {
        path: 'admin-dashboard',
        loadChildren: () =>
            import('./modules/admin-dashboard/admin-dashboard.module').then(
                (m) => m.AdminDashboardModule
            ),
        canActivate: [AdminGuard]
    },
    {
        path: 'admin/dashboard/merchandising',
        loadChildren: () =>
            import('./modules/dashboards/dashboards.module').then(
                (m) => m.DashboardsModule
            ),
        canActivate: [AdminGuard]
    },
    { path: 'settings/profile', component: EditMyProfileComponent },
    { path: 'p/:profile', component: PublicProfileComponent },
    { path: 'product/review/:product', component: ReviewFormMobileComponent },
    {
        path: 'product/view-reviews/:product',
        component: AllReviewsMobileComponent,
    },
    {
        path: 'order-details',
        loadChildren: () =>
            import('./feature/containers/order-details/order-details.module').then(
                (m) => m.OrderDetailsModule
            ),
    },
    {
        path: 'product/quiz',
        component: QuizDesktopComponent,
        // canDeactivate:[SellerGuard]
    },
    {
        path: 'style-quiz',
        component: OnboardQuizDesktopComponent,  
    },
    {
        path: 'style-quiz/my-style/:styleId',
        component: OnboardQuizDesktopComponent,  
    },
    {
        path: 'product/quiz-result',
        component: ResultPageComponent
    },
  
    {
        path: '**',
        component: LandingComponent,
        pathMatch: 'full',
        redirectTo:''
        // canActivate: [AdminGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}