import { IProductPayload } from "./../../../shared/models";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ApiService,
  MatDialogUtilsService,
  UtilsService,
} from "src/app/shared/services";
import { WishlistSnackbarService } from "../../../shared/services/wishlist-service/wishlist-snackbar.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.less"],
})
export class ProductComponent implements OnInit {
  @Input() product: IProductPayload;
  @Input() pageKey = "";
  @Input() category = "";
  starIcons: string[] = new Array();
  isVariationImageVisible = false;
  bpSubscription: Subscription;
  minPrice;
  maxPrice;
  wasMinPrice;
  wasMaxPrice;

  Math: Math = Math;
  currentPath ='';
    user: any;
  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private location: Location,
    public router: Router,
    private activeRoute: ActivatedRoute,
    private apiService: ApiService,
    private utilsService: UtilsService,
    private matDialogUtils: MatDialogUtilsService,
    private snackBarService: WishlistSnackbarService,
    private cookie: CookieService
  ) {}

  ngOnInit(): void {
    this.setRating();
    this.setPrices();
    this.user = JSON.parse(localStorage.getItem("user"));  
    this.currentPath = this.location.path();
    
    // this.activeRouteSubscription = this.activeRoute.queryParams.subscribe(params => {

    // });
  }

  onDestroy(): void {
    this.bpSubscription.unsubscribe();
  }

  setRating(): void {
    let starCount = Math.round(this.product.rating * 2) / 2;
    while (starCount > 0.5) {
      this.starIcons.push("star");
      starCount -= 1;
    }
    if (starCount && this.starIcons.length < 5) {
      this.starIcons.push("star_half");
    } else if (this.starIcons.length < 5) {
      while (this.starIcons.length < 5) {
        this.starIcons.push("star_outline");
      }
    }
  }
  navigateTo(product: any) {
    if (!(product.displayable == "preview" && this.user.authorization==0)) {
      if (
        this.category === "sectionals" ||
        this.pageKey === "mobile" ||
        this.pageKey === "quizProduct"
      ) {
        window.open(`/product/${product.sku}`, "_blank");
      } else {
        this.router.navigate([`/product/${product.sku}`]);
      }
    } else {
        this.router.navigate([`/product/${product.sku}/preview`]);
    }
  }

  routerLi(p) {
    return ["/product", p.sku];
  }

  setPrices(): void {
    // const prices = this.product.is_price? this.product.is_price.split('-'):null;
    // // const prices = ''+this.product.is_price? ''+this.product.is_price.includes('-')? (''+this.product.is_price).split('-'):[this.product.is_price]:null;
    // const wasPrices = this.product.was_price? this.product.was_price.split('-'):null;

    const { min_price, max_price, min_was_price, max_was_price } = this.product;
    this.minPrice = min_price;
    this.maxPrice = max_price;
    this.wasMinPrice = min_was_price;
    this.wasMaxPrice = max_was_price;
    // if (max_price !== min_price) {
    //     this.isRange = true;
    // }
    // this.priceObject.isPrice = Number(min_price);
    // this.isDiscounted = was_price && (was_price > min_price);
    // if (this.isDiscounted) {
    //     this.priceObject.wasPrice = was_price;
    // }

    // const {max_price, min_price, was_price} = this.product;
    // if (max_price !== min_price) {
    //     this.isRange = true;
    // }
    // this.priceObject.isPrice = Number(min_price);
    // this.isDiscounted = was_price && (was_price > min_price);
    // if (this.isDiscounted) {
    //     this.priceObject.wasPrice = was_price;
    // }

    // this.setMinMaxPrice(prices);
    // this.setWasMinMaxPrice(wasPrices);
  }

  setMinMaxPrice(prices: string[]) {
    if (prices) {
      if (prices.length === 1) {
        this.minPrice = +prices[0];
      } else {
        this.minPrice = +prices[0];
        this.maxPrice = +prices[1];
      }
    }
  }

  // + unary operator convert string to integer
  setWasMinMaxPrice(prices: string[]) {
    if (prices) {
      if (prices.length === 1) {
        this.wasMinPrice = +prices[0];
      } else {
        this.wasMinPrice = +prices[0];
        this.wasMaxPrice = +prices[1];
      }
    }
  }

  openDialog(): void {
    this.matDialogUtils.openMatDialog(this.product.sku);
  }

  wishlistProduct(sku, mark, event) {
    event.stopPropagation();
    const user = JSON.parse(localStorage.getItem("user"));
    const neverShow = localStorage.getItem("login-prompt-never-show");
    const token = this.cookie.get("token");
    if (!token) {
      // trigger signup window
      this.utilsService.openSignupDialog(false, true);
      return;
    }
    this.apiService
      .wishlistProduct(sku, mark, false)
      .subscribe((payload: any) => {
        this.product.wishlisted = mark;
        if (mark) {
          if (user.authorization === 0 && !neverShow) {
            this.matDialogUtils.openWishlistLoginPromptModal(this.product);
          } else {
            this.snackBarService.addToWishlist(sku);
          }
        } else {
          this.snackBarService.removeIfExistsProduct(sku);
        }
      });
  }
  openSigninDialog() { 
    this.utilsService.openSignupDialog(false, true);
  }
}
