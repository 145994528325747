import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { UtilsService } from '../utils/utils.service';


@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private utils: UtilsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user.authorization === this.utils.userType.adminFullSite) || (user.authorization === this.utils.userType.adminDesign);
    }

}
