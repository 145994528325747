import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { environment as env } from "src/environments/environment";
import { BehaviorSubject, of } from "rxjs";
import { delay, retry } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  query = ""; 
  plpPageNo: number = 0;
  quizCategory: string;
  shuffledImages: any[] = [];
  viewAllBtn: boolean;
  promptSearchList: any[] = [];
  categoriesList: any[] = [];
  promptCategory: string;
  discoveryList: any[] = [];
  loaderData:any[]=[{
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },
  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },  {
    main_image: "", is_new: "",  product_id: "", name: "",min_price: "",max_price: "",min_was_price: "",max_was_price: "",
    percent_discount: "", sku: "",product_status: "",url: "",style_match: false,brand_name: "",condition: "",
  },]
  productsDataPLP: any[] = [
    
  ];
  tabObs= new BehaviorSubject(false);
  apiCategory: any;
  bottomScrolled = new BehaviorSubject(false);
  noMoreProducts: boolean;
  productsUpdate$= new BehaviorSubject([]);
  brandList: any[]=[];
  constructor(private cookie: CookieService, private httpService: HttpService) {
    // env.API_BASE_HREF = 'https://seededhome.com/'
  }

  chatRequest(data: any) {
    const url = `${env.API_BASE_HREF}chat`;
    return this.httpService.post(url, data);
    // return this.httpService.get("/assets/chat.json");
    // return this.httpService.get('/assets/products.json')
  }
  getStyleReportByConvId(convId: any) {
    const url = `${env.API_BASE_HREF}style_report?conv_id=${convId}`;
    return this.httpService.get(url);
  }

  viewAllConversations() {
    const url = `${env.API_BASE_HREF}conversations/user_conv_history_detail`;
    return this.httpService.get(url);
  }
  getUserConvSummary() {
    const url = `${env.API_BASE_HREF}conversations/user_conv_history_summary`;
    return this.httpService.get(url);
  }
  viewConversationsById(id) {
    const url = `${env.API_BASE_HREF}conversations/conv_id_history?conversation_id=${id}`;
    return this.httpService.get(url);
  }
  deleteConversationsById(id) {
    const url = `${env.API_BASE_HREF}conversation?conversation_id=${id}`;
    return this.httpService.delete(url);
  }
  getPromptSearch() {
    const url = `${env.API_BASE_HREF}prompts_search`;
    return this.httpService.get(url);
  }
  getPinboardList(id: string) {
    const url = `${env.API_BASE_HREF}get_pinboardlist?conversation_id=${id}`;
    return this.httpService.get(url);
  }
  getProductBySku(sku: string): Promise<any> {
    const url = `${env.API_BASE_HREF}product-microdata/${sku}`;
    return this.httpService.get(url).toPromise();
  }
}
