import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
  UtilsService,
} from "src/app/shared/services";
import { Router } from "@angular/router";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { BuyCreditsModalComponent } from "./buy-credits-modal/buy-credits-modal.component";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { EnvService } from "src/app/shared/services/env.service";

@Component({
  selector: "app-gpt",
  templateUrl: "./gpt.component.html",
  styleUrls: ["./gpt.component.less"],
  // animations: [SlideInOutAnimation]
})
export class GptComponent implements OnInit {
  imgSrc: any;
  roomOpt = [
    "LIVING ROOM",
    "HALLWAY",
    "BEDROOM",
    "HOME OFFICE",
    "GUEST ROOM",
    "FAMILY ROOM",
  ];
  roomSelected: any;
  styleSelected: any;
  privacy: any = "public";
  styleList: any[] = [];
  user: any;
  isLoggedIn: boolean;
  expiredDate = new Date();
  originalAllRenderingList: any[] = [];
  gptAllRenderingList: any[] = [];
  originalMyRenderingList: any[] = [];
  gptMyRenderingList: any[] = [];

  throttle = 700;
  scrollDistance = 1;
  scrollUpDistance = 2;

    bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
      Breakpoints.Handset
    );
    bpSubscription: Subscription;
    isHandset = false;
  creditsRemain = null;
  file: any;
  pageNo = 1;
  isGuest: boolean;
  email: any;
  password: any;
  error: boolean;
  emailAvailable: boolean;
  renderedData: any;
  userTier: any;
  assetBaseUrl = environment.ASSET_BASE_URL;
  isAuthUpdate: boolean = true;
  activeDesign: string = "a";
  mypageNo: number = 0;
  allpageNo: number = 0;
  slideIndex: any;
  newSlideIndex: any[]=[];
  allDesdata: any[]=[];
  allMyDesData: any[]=[];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private utils: UtilsService,
    private breakpointObserver: BreakpointObserver,
    private api: ApiService,
    private matDialogUtils: MatDialogUtilsService,
    private cookie: CookieService,
    private evservice: EventEmitterService,
    private location: Location,
    public env:EnvService,
  ) {
    this.bpSubscription = this.bpObserver.subscribe(
      (handset: BreakpointState) => {
        this.isHandset = handset.matches;
      }
    );
  }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.isLoggedIn =
        JSON.parse(localStorage.getItem("user")).email.length > 0;
      this.isGuest =
        JSON.parse(localStorage.getItem("user")).authorization ===
        this.utils.userType.guest
          ? true
          : false;
    }

    if (this.isLoggedIn) {
      this.getAvailableCredits();
    }
    this.getStyle();

    this.pageNo = 1;
    this.getAllDesigns();
    this.getMyDesigns();
  }
  loggedOut(e) {
    if (e) {
      this.isLoggedIn = false;
      this.isGuest = true;
    }
  }
  loggedIn(e) {
    if (e) {
      this.isLoggedIn = true;
      this.isGuest = false;
      this.getAvailableCredits();
      this.getMyDesigns();
    }
  }
  uploadDoc(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (x: any) => {
        this.imgSrc = x.target.result;
        this.pageNo = 2;
      };
    }
  }
  getStyle() {
    this.api.getProductFinder(6).subscribe((res: any) => {
      this.styleList = res.filter((x) => x.gpt_rank > 0);
      this.styleList.sort((a, b) => (a.gpt_rank > b.gpt_rank ? 1 : -1));
    });
  }
  getAvailableCredits(allowSubmission: boolean = false) {
    this.api.getAvailableCredits().subscribe((res: any) => {
      if (res.data.length == 0) {
        this.creditsRemain = 0;
      } else {
        this.creditsRemain = res.data[0].credits_available;
        this.userTier = res.data[0].user_tier;
        if (this.userTier === "paid") {
          this.privacy = "public";
        }
        if (allowSubmission) {
          this.goToAI();
        }
      }
    });
  }

  selectRoom(s) {
    this.roomSelected = s;
    this.pageNo = 3;
    if (this.isLoggedIn) {
      this.getAvailableCredits();
    }
  }
  selectStyle(s) {
    this.styleSelected = s;
    this.pageNo = 4;
  }
  removeImg() {
    this.imgSrc = "";
  }
  changePrivacy(e) {
    this.privacy = e;
  }
  goToAI() {
    this.isLoggedIn = JSON.parse(localStorage.getItem("user")).email.length > 0;
    if (this.isLoggedIn) {
      if (this.creditsRemain > 0) {
        this.api.gptData = {
          roomSelected: this.roomSelected,
          styleSelected: this.styleSelected,
          is_private: this.privacy,
          imgSrc: this.imgSrc,
          creditsRemain: this.creditsRemain,
          isHandset: this.isHandset,
          file: this.file,
        };
        this.router.navigate(["/design/gpt/renders"]);
      }
    } else {
      this.matDialogUtils
        .openSignupDialog(false)
        .afterClosed()
        .subscribe((res) => {
          console.log(res, "RESSSS");
          if (res) {
            this.isLoggedIn = true;
            this.isGuest = false;
            this.getAvailableCredits();
          }
        });
      this.evservice.invokeFetchUser.subscribe((payload) => {
        if (payload && this.location.path() === "/design/gpt") {
          this.isLoggedIn = true;
          this.isGuest = false;
          this.getAvailableCredits(true);
        }
      });
    }
  }

  openShopPage(img_path) {
    // this.router.navigate(["/shoppable-image"]);
    this.router.navigate([]).then(() => {
      window.open(`/shoppable-image`);
    });
    localStorage.setItem("shoppableImage", JSON.stringify(img_path));
  }
  openBuyCreditsModal() {
    const dialogRef = this.dialog.open(BuyCreditsModalComponent, {
      disableClose: true,
      panelClass: "purchase-credits-container",
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "success") {
        this.getAvailableCredits();
        setTimeout(() => {
          this.pageNo = 4;
        }, 1000);
      }
    });
  }
  resetSelections() {
    this.roomSelected = null;
    this.styleSelected = null;
    this.privacy = "";
    this.imgSrc = "";
    this.file = null;
    this.pageNo = 1;
  }
  privacyChanged(privacy: string) {
    if (privacy == this.privacy) {
      this.privacy = "";
      return;
    }
    this.privacy = privacy;
  }
  onMyScrollDown() {
    this.mypageNo++;
    this.getMyDesigns();
  }
  onAllScrollDown() {
    this.allpageNo++;
    this.getAllDesigns();
  }
  checkEmail() {
    // if (this.email) {
    //   this.api.getUserByMail(this.email).subscribe((res) => {
    //     if (res.status) {
    //       this.emailAvailable = true;
    //     } else {
    //       this.emailAvailable = false;
    //     }
    //   });
    // }
  }
  // decideFunc() {
  //   if (this.email) {
  //     this.api.getUserByMail(this.email).subscribe((res) => {
  //       if (res.status) {
  //         this.emailAvailable = true;
  //         this.login();
  //       } else {
  //         this.emailAvailable = false;
  //         this.register();
  //       }
  //     });
  //   }
  // }
  // register() {
  //   if (this.email && this.password) {
  //     const formData: any = new FormData();
  //     formData.append("name", "");
  //     formData.append("email", this.email);
  //     formData.append("password", this.password);
  //     formData.append("c_password", this.password);
  //     this.isAuthUpdate = false;
  //     this.api.signup(formData).subscribe(
  //       (res) => {
  //         this.updateUser(res);
  //         this.isLoggedIn = true;
  //         this.isGuest = false;
  //         this.isAuthUpdate = true;
  //         this.getAvailableCredits();
  //       },
  //       (error: any) => {
  //         if (error.error.error.email) {
  //           this.error = true;
  //           return false;
  //         }
  //       }
  //     );
  //   }
  // }
  // login() {
  //   if (this.email && this.password) {
  //     const user = {
  //       email: this.email,
  //       password: this.password,
  //     };
  //     this.isAuthUpdate = false;
  //     this.api.login(user).subscribe((res) => {
  //       this.updateUser(res);
  //       this.isLoggedIn = true;
  //       this.isGuest = false;
  //       this.isAuthUpdate = true;
  //       this.getAvailableCredits();
  //     });
  //   }
  // }
  // updateUser(payload) {
  //   this.expiredDate.setMonth(this.expiredDate.getMonth() + 6);
  //   this.cookie.set("token", `${payload.success.token}`, this.expiredDate, "/");
  //   localStorage.setItem("user", JSON.stringify(payload.user));
  //   this.user =
  //     typeof payload.user === "string"
  //       ? JSON.parse(payload.user)
  //       : payload.user;
  // }

  getAllDesigns() {  
    this.api.getGptRenderings(this.allpageNo).subscribe((response: any) => {
      console.log(response);
      if (response && response.data) {
     
        if (this.allDesdata.length === 0) {
          this.allDesdata = [...response.data];
        } else {
          this.allDesdata = [...this.allDesdata, ...response.data];
        }
        this.originalAllRenderingList = this.allDesdata.map(
          (item) => item.image_submission
        );
        this.gptAllRenderingList = this.allDesdata.map((item) => item.image_rendering);
      }
    });
  }
  getMyDesigns() {
    if (this.isLoggedIn) {
      this.api.getGptRendersByUser(this.mypageNo).subscribe((response: any) => {
        if (response && response.data) { 
          if (this.allMyDesData.length === 0) {
            this.allMyDesData = [...response.data];
          } else {
            this.allMyDesData = [...this.allMyDesData, ...response.data];
          }

          this.originalMyRenderingList = this.allMyDesData.map(
            (item) => item.submission_image
          );
          this.gptMyRenderingList = this.allMyDesData.map((item) => item.renderlist); 
          this.gptMyRenderingList.forEach((element) => {
            this.newSlideIndex.push([]);
        }); 
        }  });
    }
  }
  concatenateUrls(assetBaseUrl: string, imageSrc: string): string {
    // Check if imageSrc is not empty and has more than one character
    if (imageSrc && imageSrc.length > 1) {
      // Remove the first character from imageSrc and concatenate with assetBaseUrl
      return assetBaseUrl + imageSrc.slice(1);
    }
    // If imageSrc is empty or has only one character, return it as is
    return imageSrc;
  }
  activeDes(a: string) {
    this.activeDesign = a;
    if (a === "m") {
      this.mypageNo = 0;
      setTimeout(() => {
        for(let i=0; i< this.gptMyRenderingList.length; i++){
          this.currentSlide(i, 1);
        } 
      }, 1000);
    }
  }
  plusSlides(mainIndex: number,n: number) {
    this.showSlides(mainIndex,(this.slideIndex += n));
}
  currentSlide(mainIndex: number, n) {
    this.showSlides(mainIndex, (this.slideIndex = n));
  }
  showSlides(mainIndex, n) {
    let i;
    let slides = document.getElementsByClassName(
      "pSlides" + mainIndex
    ) as HTMLCollectionOf<HTMLElement>;
    if (slides.length > 0) {
      let dots = document.getElementsByClassName("dot"+mainIndex);
      if (n > slides.length) {
        this.slideIndex= 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace("active-dot"+mainIndex, "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += ` active-dot${mainIndex}`;
    }
  }
  goToDesign(data){
    let ids = this.removeSpaceLowerCase(data.style)+'-'+this.removeSpaceLowerCase(data.room)+'-'+this.removeSpaceLowerCase(data.id?data.id:data.submission_id);
    this.router.navigate([`/design/gpt/${ids}`]);
  }
  removeSpaceLowerCase(str: any): string {
    return str.toString().replace(/\s/g, "").toLowerCase();
  }
}
