import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService, UtilsService } from "src/app/shared/services";
import { EnvService } from "src/app/shared/services/env.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-stripe-payment",
  templateUrl: "./stripe-payment.component.html",
  styleUrls: ["./stripe-payment.component.less"],
})
export class StripePaymentComponent implements OnInit,OnDestroy {
  isHandset = false;
  currentPage = 1;
  desPayResp: any;
  clientSecret: any;
  stripe: any;
  elements: any;
  deviceHeight: number;
  deviceWidth: number;
  errorMsg: string;
  amount: number;
  @Input() totalAmount: number;
  @Input() projectId: string; 
  @Output() executePayment = new EventEmitter();
  @Output() executeFailedPayment = new EventEmitter();
  @Output() executeFailedPromo = new EventEmitter();

  paymentInProgress: boolean;
  paymentError: string;
  promo:string;
  paymentId: any;
  promoError: string = '';

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private utils: UtilsService,
    private router: Router,
    public env: EnvService,
    private cookie: CookieService
  ) {}
ngOnDestroy(): void {
  var stripeScript = document.getElementById("stripe-script");
if (stripeScript) {
    stripeScript.remove();
}
}
  ngOnInit(): void {
    // this.invokeStripe();
    

    this.api.promoObserver$.subscribe(res=>{
      if(res){
        if(res==='--Remove--'){
          this.promo = '';
        } else {
          this.promo = res;
        }
        this.applyPromo();
      }
    })
    this.utils.isHandset().subscribe((handset) => {
      this.isHandset = handset.matches;
    });

    this.deviceHeight = window.innerHeight;
    this.deviceWidth = window.innerWidth;
    this.initStripeDesignPayment();
    
  }
  ngAfterViewInit(): void {}
  initStripeDesignPayment() {
    this.api.stripeDesignPayment().subscribe((res: any) => { 
      this.desPayResp = res.payment_info;
      this.amount = res.payment_info.amount / 100;
      this.paymentId = res.payment_info.id;
      this.clientSecret = res.payment_info.client_secret;
      const options = {
        clientSecret: this.clientSecret,
        appearance: {
          theme: "stripe",
        },
      };
  this.invokeStripe(options)
    });
  }

  applyPromo(){
    const data = {
      promo:this.promo ? this.promo.toUpperCase() : '',
      payment_id: this.paymentId
    }
    this.api.stripeDesignPayment(data).subscribe((res: any) => { 
      this.desPayResp = res.payment_info;
      this.amount = res.payment_info.amount / 100;
      this.promoError = res.promo_details.error_msg;
      this.executeFailedPromo.emit(res.promo_details)
    });
  }

  invokeStripe(options) {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://js.stripe.com/v3/";
      script.onload = () => {
        this.stripe = (<any>window).Stripe(environment.STRIPE_API_KEY);
        this.elements = this.stripe.elements(options);

      //   const paymentElement = this.elements.create("payment", {
      //     hidePostalCode: false, 
      //     // defaultValues:{
      //     //   billingDetails:{
      //     //     country:'US'
      //     //   }
      //     // },
      //     // hidePostalCode : true,
      //     // fields: {
      //     //     billingDetails: {
      //     //         address: {
      //     //             country: 'never',
      //     //             // postalCode: 'auto'
      //     //         }
      //     //     }
      //     // }
      // });
      const paymentElement = this.elements.create('payment', {
   
          fields: {
            
            billingDetails: {
              name: 'auto', // Hide name
              email: 'auto', // Hide email
              phone: 'auto', // Hide phone
              // address: {
              //   country: 'never', // Hide country
              //   postalCode: 'auto', // Always show postal code
              // },
            },
          }, 
      });

      paymentElement.update({
        defaultValues: {
          billingDetails: {
            email: 'john.smith@example.com',
            phone: '5554242424',
            address: {
              country: 'United States', // Hide country
              postal_code: '94122', // Always show postal code
            },
          }
        }
      });

        paymentElement.mount("#payment-element");

        paymentElement.update({
          defaultValues: {
            billingDetails: {
              email: 'john.smith@example.com',
              phone: '5554242424',
              address: {
                country: 'United States', // Hide country
                postal_code: '94122', // Always show postal code
              },
            }
          }
        });
      };
      window.document.body.appendChild(script);
     
    }
  }

  async pay() {
    this.paymentInProgress = true;
    this.confirmPayment();
  }
  async confirmPayment() {
    let user = JSON.parse(localStorage.getItem("user"));
    let elements = this.elements;
    const { error, paymentIntent } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://seededhome.com/design/book",
        payment_method_data: {
          billing_details: {
            name: user.firstName + " " + user.lastName,
            email: user.email,
            phone: "",
            address: {
              country: 'US'
          }
          },
        },
      },
      redirect: "if_required",
    });

    let data = {
      project_id:this.projectId,
      payment_id: paymentIntent.id,
      status: paymentIntent.status,
      payment_method: paymentIntent.payment_method,
      promo:this.promo? this.promo.toUpperCase():''
    };

    this.api.stripeCustomerCreate(data).subscribe(
      (res: any) => {
        if (res) {
          this.paymentInProgress = false; 
          if(res.status){
          this.executePayment.emit(res);
          } else {
            this.paymentInProgress = false;
            this.paymentError =
              " Please try again or use a different payment method.";
          }
        }
      },
      (err) => {
        this.paymentInProgress = false;
        this.paymentError =
          " Please try again or use a different payment method.";
      }
    );
  }
}
