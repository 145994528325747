import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanDeactivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {SigninComponent} from '../../../core/components';
import {UtilsService} from '..';
import {CancelConfirmationDialogComponent} from '../../../modules/seller/dialogs/cancel-confirmation-dialog/cancel-confirmation-dialog.component';
import {first} from 'rxjs/operators';
import { I } from '@angular/cdk/keycodes';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class SellerGuard implements CanActivate, CanDeactivate<any> {
    isHandset;

    constructor(private router: Router,
                private matDialog: MatDialog,
                private utils: UtilsService
    ) {
        utils.isHandset().subscribe(handset => {
            this.isHandset = handset.matches;
        });
    }

    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        if (!component.canDeactivate()) {
          console.log(currentRoute,'sd')  
          if(!currentRoute.routeConfig.path.includes(':order_id/PO')){
            const modalRef = this.matDialog.open(CancelConfirmationDialogComponent,
                {data: currentRoute.routeConfig.path.includes(':order_id/PO') ?'order':'product'});
            return modalRef.afterClosed().pipe(first());
          } else {
            return false;
          }
        } else {
            return true;
        }
    } 

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.isGuest()) {
            this.openSignInDialog(state.url);
            return false;
        } else {
            return true;
        }
    }

    openSignInDialog(url) {
        const width = this.isHandset ? '100%' : '35%';
        const ref = this.matDialog.open(SigninComponent, {
            width,
            panelClass: 'auth-dialog-container',
            autoFocus: false,
            data: {
                isClose: false,
                shouldRedirect: true,
                returnUrl: url
            }
        });
    }

    isGuest() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.authorization === 0;
    }
}
